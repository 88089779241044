import React from "react";
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import Tooltip from '@material-ui/core/Tooltip';

const styles = {
    defaultEmailField: {
    minWidth: 400
    },
    icons: {
        marginTop: 22
    },
    textField: {
        minWidth: "80%"
    }

}

export default class ProfileEmail extends React.Component {

state = {
    defaultEmail: this.props.accountObject.defaultEmail,
}

popDefaultEmail() {
    return(this.props.accountObject.emails.filter(email => email.email !== this.props.accountObject.defaultEmail))
}

handleChange = name => event => {
this.setState({
  [name]: event.target.value,
})
}

  render() {
    return (
        <div style={{width:"100%"}}>
        	<Grid container justify="flex-start" direction="row" alignItems="flex-start">
                {this.popDefaultEmail().map((currentEmail, i) => {
                    return (
                        <Grid item xs={4}>
                        <TextField id={i} label="Email" value={currentEmail.email} style={styles.textField}/>
                        {currentEmail.verified ? (
                            <Tooltip title="Verified">
                                <CheckCircleOutline style={styles.icons}/>
                            </Tooltip>
                            ) : (
                            <Typography/>
                            )}
                        </Grid>
                    )
                })}
            </Grid>
        </div>

    );
  }
}
