import React, { useContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import axios from 'axios';

import { UserContext } from "../Layout/UserContext"
import { AccountContext } from "../api_account/AccountContext"

axios.defaults.withCredentials = true

const label_map = {
    google:'Email',
    mo365:'Email',
    clever:'Clever ID',
    classlink:'ClassLink SISID',
}

const AddConnection = (props) => {
    const {site} = useContext(UserContext)
    const {accountObject, setAccountObject} = useContext(AccountContext)
    const [modalValues,setModalValues] = useState({type:null, connection_value:'',label:''})
    const [snack,setSnack] = useState(false)

    const handleTypeChange = (event) => {
        setModalValues({
            ...modalValues,
            type:event.target.value
        })
    }
    const handleTextChange = (event) => {
        setModalValues({
            ...modalValues,
            connection_value:event.target.value
        })        
    }

    const addConnection = (value) => {
        axios.post(process.env.REACT_APP_BTS_API_URL + "/api/account/"+props.account['id']+"/connections?site=" + site.site, modalValues)
            .then(res => {
                let connections_array = accountObject.account.connections[modalValues.type]
                setAccountObject({
                    ...accountObject,
                    account:{
                        ...accountObject.account,
                        connections:{
                            ...accountObject.account.connections,
                            [modalValues.type]:[
                                res.data.data,
                                ...accountObject.account.connections[modalValues.type]
                            ]
                        }
                    } 
                })
                props.closeModal()
                setSnack(true)
            })
    }
    const handleClose = () => {
        setSnack(false)
    }

    return(
    	<div>
	    	<Dialog open={props.open} onClose={props.closeModal}>
	    		<DialogTitle>
		    		<Typography>Add a connection</Typography>
		    	</DialogTitle>
		    	<DialogContent style={{width:420}}>
                <FormControl style={{width:'100%',marginBottom:20}}>
                    <InputLabel>Connection Type</InputLabel>
                    <Select
                        value={modalValues.type}
                        onChange={handleTypeChange}
                    >
                    <MenuItem value={'google'}>Google</MenuItem>
                    <MenuItem value={'mo365'}>Microsoft</MenuItem>
                    <MenuItem value={'clever'}>Clever</MenuItem>
                    <MenuItem value={'classlink'}>ClassLink</MenuItem>
                    </Select>
                </FormControl>
                {modalValues.type ? (
                    <TextField 
                        fullWidth 
                        onChange={handleTextChange} 
                        variant="outlined"
                        label={label_map[modalValues.type]}
                        value={modalValues.connection_value}
                    />
                ):(<div/>)}
				</DialogContent>
				<DialogActions>
					<Button onClick={props.closeModal} color="primary">Cancel</Button>
					<Button onClick={()=>{addConnection()}} color="primary">Add Connection</Button>
		 		</DialogActions>
		    	</Dialog>
                <Snackbar
                    anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                    }}
                    open={snack}
                    onClose={handleClose}
                    autoHideDuration={4000}
                    message="Connection Added"
                />
    	</div>
    )
}
export default AddConnection