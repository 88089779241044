import React, { useContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';

import { UserContext } from "../Layout/UserContext"
import { OrgInfoContext } from "../api_org/OrgInfoContext"

axios.defaults.withCredentials = true



const DeleteAccountModal = (props) => {
    const {site} = useContext(UserContext)

    const deleteOrganization = async () => {
        let deleteAccountUrl = process.env.REACT_APP_BTS_API_URL + '/api/account/'+props.accountID+'?site=' + site.site
        let deleteAccountOptions = {
            method: 'DELETE',
            credentials: 'include',
            headers: {'Content-Type': 'application/json'}
        }
        const accountResponse = await fetch(deleteAccountUrl, deleteAccountOptions)
        props.closeModal()
    }
    
    return(
        <Dialog open={props.open} onClose={props.closeModal} >
            <DialogTitle>
                <Typography>Deleting Account</Typography>
            </DialogTitle>
                <DialogContent style={{width:500}}>
                    <Typography>Are you sure you want to delete this account?</Typography>
                </DialogContent>    
            
            <DialogActions>
                <Button onClick={props.closeModal} color="primary">Cancel</Button>
                <Button onClick={deleteOrganization} color="primary">Permanently Delete</Button>
            </DialogActions>
        </Dialog>
    )
}
export default DeleteAccountModal