import React, { useContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import axios from 'axios';

import { UserContext } from "../Layout/UserContext"
import { OrgInfoContext } from "../api_org/OrgInfoContext"

axios.defaults.withCredentials = true

const DeleteOrgModal = (props) => {
    const {site} = useContext(UserContext)
    const [snack,setSnack] = useState(false)
    const [textData, setTextData] = useState("")
    const [deleteEnabled, setDeletedEnabled] = useState(true)
    const {workingOrg,setWorkingOrg} = useContext(OrgInfoContext)

    const deleteOrg= () => {
        let request_data = {'displayName':textData}
        let request_url = process.env.REACT_APP_BTS_API_URL + '/api/organization/'+workingOrg.detailedOrg.bulb_id+'?site=' + site.site
        axios.delete(request_url,{data:request_data}).then(response => {
            setWorkingOrg({detailedOrg:{id:''}})
        })
        props.closeModal('delete')
        setSnack(true)
    }
    
    const handleClose = () => {
        setSnack(false)
    }

    const handleChange = (event) => {
        setTextData(event.target.value)
        if (event.target.value == workingOrg.detailedOrg.name) {setDeletedEnabled(false)}
        else {setDeletedEnabled(true)}
    }

    return(
        <div>
        <Dialog open={props.open} onClose={()=>props.closeModal('delete')} >
            <DialogTitle>
                <Typography>Deleting Organization</Typography>
            </DialogTitle>
                <DialogContent style={{width:500}}>
                    <Typography>Are you sure you want to delete this organization:</Typography>
                    <Typography align='center' variant='h5' style={{marginTop:10,marginBottom:10}}>{workingOrg.detailedOrg.name}</Typography>
                    <Typography><b>Warning: This cannot be undone</b></Typography>
                    <Typography style = {{marginTop:20}}>Please type the full organization name in the box to confirm deletion.</Typography>
                    <TextField value={textData} onChange={(event =>handleChange(event))} fullWidth/>
                </DialogContent>    
            
            <DialogActions>
                <Button onClick={()=>props.closeModal('delete')} color="primary">Cancel</Button>
                <Button onClick={deleteOrg} color="primary" disabled={deleteEnabled}>Permanently Delete</Button>
            </DialogActions>

        </Dialog>
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={snack}
            onClose={handleClose}
            autoHideDuration={4000}
            message="Organization queued for deletion"
        />      
        </div>
    )
}
export default DeleteOrgModal