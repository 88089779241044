import React from "react";
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid';

const dateFixer = (dateToBeFixed) => {
    if (dateToBeFixed) {
        let slicedDate = dateToBeFixed.slice(0,10).split('-')
        let fixedDate = slicedDate[1]+"-"+slicedDate[2]+"-"+slicedDate[0]
        return fixedDate
    }
    else {
        return "No Date"
    }
}

export default class MetricsInfo extends React.Component {

state={

}

componentDidMount() {
    this.forceUpdate();
  }

  render() {

    return (
        <div>
        	<Grid container justify="space-between" direction="row" alignItems="flex-start">                       
                <Grid item xs={4}>
                    <Typography variant="h6" color="textSecondary">Creation Date:</Typography><Typography variant="h6">{dateFixer(this.props.accountObject.metrics.creationDate)}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h6" color="textSecondary">First Login Date:</Typography><Typography variant="h6">{dateFixer(this.props.accountObject.metrics.firstLoginDate)}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h6" color="textSecondary">Last Login Date:</Typography><Typography variant="h6">{dateFixer(this.props.accountObject.metrics.lastLoginDate)}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <div style={{height:"15px"}}/>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h6" color="textSecondary">Draft Pages:</Typography><Typography variant="h6">{this.props.accountObject.metrics.numDraftPages}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h6" color="textSecondary">Published Pages:</Typography><Typography variant="h6">{this.props.accountObject.metrics.numPublishedPages}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h6" color="textSecondary">Collections:</Typography><Typography variant="h6">{this.props.accountObject.metrics.numCollections}</Typography>
                </Grid>
            </Grid>
        </div>

    );
  }
}
