import React from "react";
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';

import Search from '@material-ui/icons/Search'

export default class SearchBar extends React.Component {

state = {
	searchFieldValue: ""
}

handleChange = name => event => {
this.setState({
  [name]: event.target.value,
})
}
	render() {
		return(
			<Grid container justify="flex-start" direction="row" alignItems="flex-start">
				<Grid item xs={12}>
				<TextField 
					id="searchFieldValue" 
					onChange={this.handleChange('searchFieldValue')}
					onKeyPress={event => {if (event.key === 'Enter') {this.props.searchAccountID(this.state.searchFieldValue)}}}
					variant="outlined" label="Search for account (Email, Username, ID)" 
					value={this.state.searchFieldValue} 
					style={{marginTop:12,marginLeft:"10%",width:"80%"}}
			        InputProps={{
			        endAdornment: (
			            <InputAdornment position="end">
				            <IconButton
				                edge="end"
				                aria-label="Toggle password visibility"
				                onClick={event => {this.props.searchAccountID(this.state.searchFieldValue)}}
				            >
				            <Search/>
				            </IconButton>
			            </InputAdornment>
			          ),
			        }}
				/>
				</Grid>
				<Grid xs={12}>
				<div style={{width:"100%", marginTop:12,marginBottom:15}}><Divider/></div>
				</Grid>
			</Grid>
			)
	}
}