import React, { useContext } from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider';

import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import OrganizationsDropDownMenu from "../dropdown_menus/OrganizationsDropDownMenu";
import SchoolDropDownMenu from "../dropdown_menus/SchoolDropDownMenu";
import GradeDropDownMenu from "../dropdown_menus/GradeDropDownMenu";
import PositionDropDownMenu from "../dropdown_menus/PositionDropDownMenu";
import AccountTypeDropDownMenu from "../dropdown_menus/AccountTypeDropDownMenu";

import { FormContext } from "./FormContext"
import { validateEmail, validateUsername, validateNameLength } from "./FormValidators"

const styles = {
    root: {
        width:"calc(100%-240px)", 
        marginTop:100,
        marginLeft: 240
    },
    formFields: {
        width: '50%',
        marginLeft:'25%'
    },
    leftFields: {
        minWidth: '40%',
        marginTop: 20
    },
    rightFields: {
        minWidth: '40%',
        marginLeft: '5%',
        marginTop: 20
    },
    emailField: {
        maxWidth: '85%',
        marginTop: 20
    },
    submitButton: {
        marginLeft: '5%',
        marginTop: 20
    }
}

const CreateAccountForm = (props) => {
    const {formInputs, setFormInputs} = useContext(FormContext)

    const handleTextbox = name => event => {
        switch(name) {
            case "emailInput":
                setFormInputs({
                    ...formInputs,
                    emailInput: {value: event.target.value, valid: validateEmail(event.target.value)}
                })
                break
            case "firstNameInput":
                setFormInputs({
                    ...formInputs,
                    firstNameInput: {value: event.target.value, valid: validateNameLength(event.target.value)}
                })
                break
            case "lastNameInput":
                setFormInputs({
                    ...formInputs,
                    lastNameInput: {value: event.target.value, valid: validateNameLength(event.target.value)}
                })
                break
            case "usernameInput":
                setFormInputs({
                    ...formInputs,
                    usernameInput: {value: event.target.value, valid: validateUsername(event.target.value)}
                })
                break
            default:
                setFormInputs({
                    ...formInputs, [name]:{...formInputs[name], value: event.target.value}
                })
                break
        }
    }

    const handleCheckbox = name => event => {
        setFormInputs({
            ...formInputs,
            [name]: event.target.checked
        })
    }

    const validateForm = () => {
        let nullsToCheck = ["firstNameInput","emailInput","usernameInput","roleInput","accountTypeInput"]
        for(let i = 0; i < nullsToCheck.length; i++){
            if(formInputs[nullsToCheck[i]].value === "") {
                return true
            }
            if(formInputs[nullsToCheck[i]].valid === false) {
                return true
            }
        }
        return false
    }

    const lockForPasswordType = () => {
        if (formInputs.accountTypeInput.value === "password") {
            return true
        }
    }

    return(
        <div style={styles.root}>
            <div style={styles.formFields}>
                <FormGroup row>
                    <TextField 
                        id="firstNameInput" 
                        onChange={handleTextbox('firstNameInput')}
                        variant="outlined" label="First Name"
                        value={formInputs.firstNameInput.value}
                        required="true"
                        error={!formInputs.firstNameInput.valid}
                        style={styles.leftFields}
                    />
                    <TextField 
                        id="lastNameInput" 
                        onChange={handleTextbox('lastNameInput')} 
                        variant="outlined" 
                        label="Last Name" 
                        value={formInputs.lastNameInput.value} 
                        error={!formInputs.lastNameInput.valid}
                        style={styles.rightFields} 
                    />
                </FormGroup>
                <FormGroup row>
                    <TextField 
                        fullWidth 
                        id="emailInput" 
                        onChange={handleTextbox('emailInput')}
                        variant="outlined" label="Email" 
                        value={formInputs.emailInput.value} 
                        required="true" 
                        error={!formInputs.emailInput.valid && Boolean(formInputs.emailInput.value)}
                        style={styles.emailField}
                    />
                </FormGroup>
                <FormGroup row>
                    <TextField 
                        id="usernameInput" 
                        onChange={handleTextbox('usernameInput')} 
                        variant="outlined" label="Username" 
                        value={formInputs.usernameInput.value} 
                        required="true" 
                        error={!formInputs.usernameInput.valid} 
                        helperText="No symbols or spaces" 
                        style={styles.leftFields}
                    />
                    <PositionDropDownMenu
                        styles={styles.rightFields}
                    />
                </FormGroup>
                <FormGroup row>
                    <OrganizationsDropDownMenu
                        values = {formInputs}
                        setValues = {setFormInputs}
                        styles={styles}
                    />
                    <AccountTypeDropDownMenu
                        styles={styles.rightFields}
                    />
                </FormGroup>
                
                {formInputs.organizationInput.value && false? (
                    <SchoolDropDownMenu 
                        values = {formInputs}
                        setValues = {setFormInputs}
                        styles={styles}
                    />
                ) : (
                    <div></div>
                )}

                {formInputs.organizationInput.value && formInputs.roleInput.value=='STUDENT' ? (
                    <GradeDropDownMenu
                        values = {formInputs}
                        setValues = {setFormInputs}
                        styles={styles}
                    />
                ):(
                    <div></div>
                )}

                <FormGroup row>
                    <FormControlLabel 
                        control={
                            <Checkbox 
                                checked={formInputs.welcomeLetterToggle} 
                                onChange={handleCheckbox('welcomeLetterToggle')} 
                                value="welcomeLetterToggle" 
                                disabled={lockForPasswordType()}
                            />}
                        label="Send Welcome Letter"
                        style={styles.leftFields}
                    />
                    {formInputs.organizationInput.value ? (
                        <FormControlLabel 
                            control={
                                <Checkbox 
                                    checked={formInputs.org_admin}
                                    onChange={handleCheckbox('org_admin')}
                                    value="org_admin"
                                />}
                            label="Org Admin"
                            style={styles.rightFields}
                        />
                    ):(
                        <div></div>
                    )}
                </FormGroup> 
            </div>
            <div style={{width:"100%", marginTop:20, marginBottom:10}}><Divider/></div>
            <div style={styles.formFields}>
            <FormGroup row>
                <Typography style={styles.leftFields}>* Required Field</Typography>
                <Button 
                    style={styles.submitButton}
                    onClick={props.submitForm}
                    variant="contained" 
                    color="primary" 
                    disabled = {validateForm()}>
                Create Account
                </Button>
            </FormGroup> 
            </div>
        </div>
    )
}

export default CreateAccountForm