import React, { useState, useEffect, useContext} from "react";

import NotesPanel from './Tab-Overview-Notes'
import OrganizationDetails from './Tab-Overview-Details'
import AdminPanel from './Tab-Overview-Admin'

import { UserContext } from "../Layout/UserContext"

const styles = {
	root: {
		width: '100%',
		display: "flex"
	},
	leftSide: {
		width: "58%"
	},
	rightSide: {
		width: "40%",
		marginTop: 20
	}
}

const OrganizationStats = (props) => {
	const {site} = useContext(UserContext)
	return(
		<div style={styles.root}>
		<div style={styles.leftSide}>
			<OrganizationDetails/>
		</div>
		<div style={styles.rightSide}>
		{site.roles.org_delete ? (<AdminPanel/>) : (<div/>) }
			<NotesPanel/>
		</div>
		</div>
	)
}
export default OrganizationStats