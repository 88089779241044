import React, { useContext, useState } from "react";
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography'
import axios from 'axios'

import ProfileInfoSubPage from "../components/api_account/ProfileInfoSubPage"

import ProfileNav from "../components/api_account/ProfileNav"
import ConnectionCarousel from "../components/api_account/AccountsConnectionsDisplay"
import VirtualGroupTable from "../components/api_account/GroupsTableVirtualized"
import OrgInfo from "../components/api_account/OrgInfo"
import SearchBar from "../components/api_account/AccountSearchbar"
import AccountUpdateModal from "../components/modals/AccountUpdateModal"

import { UserContext } from "../components/Layout/UserContext"
import { AccountContext } from "../components/api_account/AccountContext"

axios.defaults.withCredentials = true

let styles = {
  root: {
    width:"calc(100%-240px)",
    marginTop: 70,
    marginLeft: 240
    }
}

const AccountFormHolder = styled.div`
  font-family: "sans-serif";
  width:"100%";
`;

const ApiAccountLayout = (props) => {
    const {site} = useContext(UserContext)
    const [accountObject,setAccountObject] = useState({
        account: '',
        accountObjectToUpdate:{}
    })
    const [pageState,setPageState] = useState({
        accountObjectLoaded: false,
        loadingGroups:false,
        editMode: false,
        errorText: "",
        changesMade: false
    })
    const [currentPage,setCurrentPage] = useState("profileInfoPage")
    const [detailedGroups,setDetailedGroups] = useState([])
    const [avStyle,setAvStyle] =useState({})
    const [editModal,setEditModal] = useState({modalopen:false})

    const getUserGroups = async (groupID) => {
        let res = await axios.get(process.env.REACT_APP_BTS_API_URL+'/api/group/'+groupID+'?site='+site.site)
        let data  = await res.data;
        return(data);
    }

    const handleFetchGroup = async (groupArray) => {
        let detailedGroupsArray = []
        for (let i = 0; i < groupArray.length; i++){
            let detailedGroup = await getUserGroups(groupArray[i].groupId)
            detailedGroupsArray.push(detailedGroup)
            setDetailedGroups(detailedGroupsArray)
        }
        setPageState(prevState=>({...prevState,loadingGroups:false}))
    }

    const avatarStyle = () => {
        var randomColor = require('randomcolor')
        setAvStyle({
            width: "5vw",
            height: "5vw",
            fontSize: 40,
            marginLeft:"75%",
            marginTop: 25,
            marginBottom: 25,
            backgroundColor:randomColor({luminosity: 'dark'})
        })
    }

    const handleSearchAccount = (accountID) => {
        setAccountObject({account: ''})
        setDetailedGroups([])
        setPageState({...pageState,loadingGroups:true,editMode:false,changesMade: false})
        let searchObject = {searchBy:"",searchValue:accountID}
        axios.post(process.env.REACT_APP_BTS_API_URL+'/api/account/search'+'?site='+site.site,searchObject)
            .then(res => {
                if (res.data) {
                    let accountObj = res.data
                    setAccountObject({
                        account: res.data,
                        accountObjectToUpdate: {
                            firstName:accountObj.firstName,
                            lastName:accountObj.lastName,
                            displayName:accountObj.displayName,
                            defaultEmail:accountObj.defaultEmail,
                            grade: accountObj.grade,
                            position:accountObj.position}
                    })
                    handleFetchGroup(accountObj.groupMemberships.filter(group => {return group.type==="CLASS" || group.type==="OTHER"}))

                } else {
                    let errorTemp = "No Account Found for: " + accountID
                    setPageState({...pageState,errorText:errorTemp})
                }
            })
        avatarStyle()
    }

    const handleChange = (event, name) => {
        if (pageState.editMode){
            setPageState({...pageState,changesMade:true})
            setAccountObject({
                ...accountObject,
                accountObjectToUpdate:{
                    ...accountObject.accountObjectToUpdate,
                    [name]:event.target.value}
            })
        }
    }

    const handleSuccessfulPatch = (key, value) => {
        setAccountObject({
            ...accountObject,
            account:{
                ...accountObject.account,
                [key]: value
            }
        })
    }

    const handlePageChange = (value) => {
        closeModalReset()
        setCurrentPage(value)
    }

    const closeModal = () => {
        setEditModal(false)
    }

    const closeModalReset = () => {
        closeModal()
        setPageState({...pageState,editMode:false,changesMade: false})
    }

    const pagesDict = {
        profileInfoPage:
            <ProfileInfoSubPage
                accountObject = {accountObject.account}
                accountObjectToUpdate = {accountObject.accountObjectToUpdate}
                pageState = {pageState}
                setPageState = {setPageState}
                handleChange={handleChange}
                setEditModal = {setEditModal}
            />,
        groupsMembershipsPage:
            <VirtualGroupTable
                groupsArray={detailedGroups}
                loadingGroups={pageState.loadingGroups}
            />,
        connectionsInfoPage:
            <ConnectionCarousel account={accountObject.account}/>,
        organizationInfoPage:
            <OrgInfo account={accountObject.account}/>
    }

    let CurrentPage = pagesDict[currentPage]
    return (
        <AccountContext.Provider value={{accountObject, setAccountObject}}>
        <div style={styles.root}>
            <Grid item xs={12}><SearchBar searchAccountID={handleSearchAccount}/></Grid>
            {accountObject.account ? (
                <AccountFormHolder>
                    <Grid container style={{width:"100%"}}>
                        <Grid item xs={2}>
                            <ProfileNav accountObject={accountObject.account} handlePageChange={handlePageChange} avStyle = {avStyle}/>
                        </Grid>
                        <Grid item xs={10} style={{marginTop:5}}>
                            {CurrentPage}
                        </Grid>
                    </Grid>
                </AccountFormHolder>
            ) : (
                <Typography>{pageState.errorText}</Typography>
            )}
        </div>
        <AccountUpdateModal
            modalOpen = {editModal}
            closeModal = {closeModal}
            closeModalReset = {closeModalReset}
            accountObject = {accountObject}
            handleSuccessfulPatch = {handleSuccessfulPatch}
        />
        </AccountContext.Provider>
    )
}

export default ApiAccountLayout
