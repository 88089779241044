import React, { useState, useEffect, useContext} from "react";
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { UserContext } from "../Layout/UserContext"
import { OrgInfoContext } from "./OrgInfoContext"

import OrgFTPSyncSetup from "../modals/OrgFTPSyncSetup"
import OrganizationStats from "./Tab-Stats"
import OrganizationOverview from "./Tab-Overview"
import OrganizationSync from "./Tab-Sync"
import OrganizationReport from "./Tab-Report"

let styles = {
	root: {
	    width:'calc(100% - 210px)',
	    height: "calc(100vh-64px)",
	    marginLeft: 210,
	    marginTop: 64,
	    display: "block"
    },
    title: {
    	marginTop: -45,
    	marginBottom: 5
    	
    },
    backButton: {
    	
    },
    tabigation: {
    	
    }
}

const defaultModalConfig = {
	open: false
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const OrgInfo = (props) => {
	const {site} = useContext(UserContext)
	const {workingOrg,setWorkingOrg} = useContext(OrgInfoContext)
	const [orgSetupModal, setOrgSetupModal] = useState(defaultModalConfig)
	const [value, setValue] = React.useState(0)
	const clearOrg = () => {
		setWorkingOrg({
			...workingOrg,
			detailedOrg: {}
		})
	}

	const handleChange = (event, newValue) => {
		setValue(newValue)
	}

	const compMap = {
		0: <OrganizationOverview detailedOrg={workingOrg}/>,
	}

	function TabPanel(props) {
	  const { children, value, index, ...other } = props;
	  return (
	    <div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
	    >
		{compMap[index]}
	    </div>
	  );
	}

	return(
		<div style={styles.root}>
			<IconButton color="primary" style={styles.backButton} onClick = {clearOrg} size="large">
				<ArrowBackIcon fontSize="large"/>
			</IconButton>
			<Typography variant='h4' style={styles.title} align='center'>{workingOrg.detailedOrg.name}</Typography>
			<Divider/>
			<div style={styles.tabigation}>
				<Tabs value={value} onChange={handleChange} aria-label="simple tabs example" centered>
					<Tab label="Overview" {...a11yProps(0)} />

					
				</Tabs>
				<Divider style={{marginTop: 2}}/>
				<TabPanel value={value} index={0}/>

			</div>
		</div>
	)
}
export default OrgInfo