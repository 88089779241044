import React, { useState } from 'react'
import OrgSearchBar from '../components/api_org/OrgSearchBar'
import OrgInfo from '../components/api_org/OrgInfo'
import { OrgInfoContext } from '../components/api_org/OrgInfoContext'

const styles = {
	root: {
		display: "flex",
		width: "100%"
	}
}

const defaultOrg = {
	detailedOrg: {},
	modals: {
		noteModal: {
			open: false,
			value:""
		}
	}
}

const ViewOrg = (props) => {
	const [workingOrg,setWorkingOrg] = useState(defaultOrg)

	return(
		<OrgInfoContext.Provider value={{workingOrg, setWorkingOrg}}>
		<div style={styles.root}>
			{workingOrg.detailedOrg.id ? (
				<OrgInfo/>
				):(
				<OrgSearchBar/>
			)}

		</div>
		</OrgInfoContext.Provider>
	)
}

export default ViewOrg