export const validateEmail = (value) => {
    // eslint-disable-next-line
    var reg = /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
    value = value.replace(/\s/g, '')
    if (reg.test(value) === false) {
        return false;
    }
    return true;
}

export const validateUsername = (value) => {
	let cleanedName = value.replace(/[^\w-]/gi, '')
	if (cleanedName !== value) {
    	return false
	}
	return true
}

export const validateNameLength = (value) => {
    if (value.length >= 73) {
        return false
    }
    return true
}