import React from "react";
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

import ProfileInfo from "./ProfileInfo"
import ProfileEmail from "./ProfileEmails"
import MetricsInfo from "./MetricsInfo"

const styles = {
    divider: {
        width:"100%", 
        marginBottom:25, 
        marginTop:2, 
        marginLeft:"-4%"
    }
}

const EditButton = ( props ) => {
    function setEditTrue() {props.setPageState({...props.pageState,editMode:true})}
    function setEditFalse() {props.setPageState({...props.pageState,editMode:false})}
    function openConfirmModal() {props.setEditModal({modalopen:true})}

    let button_text = 'Edit'
    let button_function = setEditTrue
    
    if (props.pageState.editMode === false){
        button_text = 'Edit'
        button_function = setEditTrue
    }else{
        if (props.pageState.changesMade === false){
           button_function = setEditFalse
           button_text = 'Cancel' 
        }else{
            button_text = 'Save'
            button_function = openConfirmModal
            
        }
    }
    return (<Button variant='contained' style={{width:100,marginRight:"6.8%", float:"right"}} onClick = {button_function}>{button_text}</Button>)
}

const ProfileInfoSubPage = ( props ) => {

    return (
        <div style={{marginLeft:"4%"}}>                      
                <Typography variant="h4" style={{display:'inline'}}>Profile Info</Typography>
                <EditButton 
                    accountObject ={props.accountObject} 
                    accountObjectToUpdate ={props.accountObjectToUpdate} 
                    pageState = {props.pageState}
                    setPageState = {props.setPageState}
                    setEditModal= {props.setEditModal}
                />
                <div style={styles.divider}><Divider/></div>
                <ProfileInfo 
                    accountObject ={props.accountObject} 
                    accountObjectToUpdate ={props.accountObjectToUpdate} 
                    handleChange={props.handleChange} 
                    editMode = {props.pageState.editMode}
                />

                <Typography variant="h5" style={{marginTop:"5%"}}>Additional Emails</Typography>
                <div style={styles.divider}><Divider/></div>
                <ProfileEmail accountObject ={props.accountObject} handleChange={props.handleChange}/>
                  
                <Typography variant="h5" style={{marginTop:"5%"}}>Metrics</Typography>
                <div style={styles.divider}><Divider/></div>
                <MetricsInfo accountObject={props.accountObject}/>
        </div>

    );
}

export default ProfileInfoSubPage