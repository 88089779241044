import React, { useState, useEffect, useContext } from "react";
import ReactDOM from 'react-dom';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import axios from 'axios';

import { UserContext } from "../Layout/UserContext"
import CreateOrgModal from "../modals/CreateOrgModal"

const orgModalDefault = {
    open: false,
    value: ""
}

const OrganizationsDropDownMenu = (props) => {
    const {site} = useContext(UserContext)

    const [orgLabelWidth, setOrgLabelWidth] = useState(0)
    const [orgLabelRef, setOrgLabelRef] = useState()
    const [orgList, setOrgList] = useState()
    const [orgModal, setOrgModal] = useState(orgModalDefault)

    let formInputs = props.values
    let setFormInputs = props.setValues

    useEffect(() => {
        axios.get(process.env.REACT_APP_BTS_API_URL + '/api/organization/names?site=' + site.site).then(orgList => {
        setOrgList(orgList.data)
        })
    },[site])

    useEffect(() => {
        orgLabelRef ? (setOrgLabelWidth(ReactDOM.findDOMNode(orgLabelRef).offsetWidth)) : (setOrgLabelWidth(0))
    })
    
    const pickOrg = (event) => {
        let etv = event.target.value
        if(etv === 'createOrg') {
            setOrgModal({
                ...orgModal,
                open: true
            })
        } else{
            setFormInputs({
                ...formInputs,
                'organizationInput': {value: etv},
                'schoolInput': {value:''},
                'gradeInput': {value:''}
            })
        }
    }

    return(
        <FormControl variant="outlined" style={props.styles.leftFields}>
            <InputLabel htmlFor="outlined-Org-Label" ref={ref => {setOrgLabelRef(ref)}}>Organization</InputLabel>
            <Select 
                input={<OutlinedInput id="outlined-Org-Label" labelWidth={orgLabelWidth}/>} 
                onChange={pickOrg}
                value={formInputs.organizationInput.value}
            >
            <MenuItem value="createOrg"> --Create Org-- </MenuItem>
            
            <Divider style={{marginTop:8}}/>
                {orgList ? (
                    orgList.map( org => 
                        <MenuItem value={org.bulb_id} style={{marginLeft: 0}}>{org.name}</MenuItem>
                    )) : (
                        <MenuItem>Loading...</MenuItem>
                    ) 
                }

            </Select>
            <CreateOrgModal value={orgModal} setValue={setOrgModal} orgList={orgList} setOrgList={setOrgList}/>
        </FormControl>
    )
}

export default OrganizationsDropDownMenu