import React, { useContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';

import { UserContext } from "../Layout/UserContext"

axios.defaults.withCredentials = true

const AccountUpdateModal = (props) => {
    const {site} = useContext(UserContext)

    const updateAccount = () => {
        let updateObject = {}
        let accountID = props.accountObject.account.id
        for (const key in props.accountObject.accountObjectToUpdate) {
            if (props.accountObject.accountObjectToUpdate[key] != props.accountObject.account[key]) {
                updateObject[key] = props.accountObject.accountObjectToUpdate[key]
            }
        }
        axios.patch(process.env.REACT_APP_BTS_API_URL+'/api/account/'+ accountID +'?site='+site.site,updateObject)
            .then(res => {
                if (res.status === 200) {
                    for (const key in updateObject) {
                        props.handleSuccessfulPatch(key, updateObject[key])
                    }
                }
                props.closeModalReset()
            })
    }

    const CompareAccounts = (props) => {
        let fieldsToUpdate = {}
        let fieldMap = {firstName:"First Name",lastName:"Last Name",displayName: "Display Name", position: "Position", grade: "Grade"}

        for (const key in props.accountObject.accountObjectToUpdate) {
            if (props.accountObject.accountObjectToUpdate[key] != props.accountObject.account[key]) {
                fieldsToUpdate[key] = props.accountObject.accountObjectToUpdate[key]
            }
        }

        let update_content =
            Object.keys(fieldsToUpdate).map(field =>
                <Typography><b>{fieldMap[field]}:</b> {props.accountObject.accountObjectToUpdate[field]}</Typography>
            )

        return(update_content)
    }

    return(
        <Dialog open={props.modalOpen.modalopen} onClose={props.closeModal} >
            <DialogTitle>
                <Typography>Updating Account</Typography>
            </DialogTitle>
                <DialogContent style={{width:500}}>
                    <Typography>Are you sure you want to make these changes?</Typography>
                    <CompareAccounts accountObject = {props.accountObject}/>
                </DialogContent>

            <DialogActions>
                <Button onClick={props.closeModal} color="primary">Cancel</Button>
                <Button onClick={updateAccount} color="primary">Confirm</Button>
            </DialogActions>
        </Dialog>
    )
}
export default AccountUpdateModal
