import React, { useState, useEffect, useContext} from "react";
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import axios from 'axios';

import { UserContext } from "../Layout/UserContext"
import { OrgInfoContext } from "./OrgInfoContext"

axios.defaults.withCredentials = true

let styles = {
	root: {
	    width:'calc(80% - 223px)',
	    marginTop: 75,
	    marginLeft: 'calc(10vw + 223px)',
	    display: "block"
    },
	searchBar: {
		width:'80%'
	},
	createOrgButton: {
		width:'14%',
		marginLeft: '3%',
		marginTop: 10
	},
    listItems: {
    	height: 'calc(100vh - 210px)',
	   	position: 'relative',
	    overflowY: 'auto'
    }
}

const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
const getSuggestions = (value, orgsList)=> {
	const escapedValue = escapeRegexCharacters(value.trim());
	if (escapedValue === '') {
		return [];
	}
	const regex = new RegExp('^' + escapedValue, 'i');
	const suggestions = orgsList.filter(org => regex.test(org.name));

	if (suggestions.length === 0) {
		return [
			{ isAddNew: true }
		]
	}
	return suggestions;
	}

const trimName = (name) => {
	if(name && name.length > 25){
		return(name.substring(0,29) + "...")
	}else{
		return(name)
	}
}

const OrgSearchBar = (props) => {
	const {site} = useContext(UserContext)
	const {workingOrg,setWorkingOrg} = useContext(OrgInfoContext)
	const [orgList, setOrgList] = useState()
	const [suggestions, setSuggestions] = useState([])
	const [searchValue, setSearchValue] = useState()

    useEffect(() => {
        axios.get(process.env.REACT_APP_BTS_API_URL + '/api/organization/names?site=' + site.site).then(bulbOrgList => {
        	setOrgList(bulbOrgList.data)
        	setSuggestions(bulbOrgList.data)
        })
    },[site])

	const onChange = (event) => {
		if(event.target.value){
			let suggestionsArray = getSuggestions(event.target.value,orgList)
			setSuggestions(suggestionsArray)
			setSearchValue(event.target.value)
		}else{
			setSuggestions(orgList)
			setSearchValue(event.target.value)
		}
	}

	const onClick = (suggestion) => {
		axios.get(process.env.REACT_APP_BTS_API_URL + '/api/organization/'+suggestion.bulb_id+'?site=' + site.site).then(loadedOrg => {
		setWorkingOrg({
			...workingOrg,
			detailedOrg:loadedOrg.data
		})
		})
	}

	return(
		<div style={styles.root}>
			<TextField 
				id="searchFieldValue"
				autoComplete='off'
				style={styles.searchBar}
				variant="outlined" label="Search for Organization"
				onChange={(e) => onChange(e)} value={searchValue}
			/>
			<Button variant="outlined" style={styles.createOrgButton}>Create new org</Button>
			<Divider style={{marginTop:5}}/>
			<div style={{overflow:'auto'}}>
			<List component="nav" style={styles.listItems}>
				{suggestions && suggestions.length >=1 ? 
					(suggestions.map(suggestion => {
						return (
							<ListItem button onClick={() => onClick(suggestion)}>
							<ListItemText primary={suggestion.name}/>
							</ListItem>
						)
					})) : (<ListItem><ListItemText primary='Loading Orgs'/></ListItem>)
				}
			</List>
			</div>
		</div>
	)
}
export default OrgSearchBar