import React, { useState, useEffect, useContext} from "react";
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Info from '@material-ui/icons/Info'
import Business from '@material-ui/icons/Business'
import Power from '@material-ui/icons/Power'
import Group from '@material-ui/icons/Group'
import Delete from '@material-ui/icons/Delete'

import DeleteAccountModal from "../modals/ConfirmAccountDelete"

import { UserContext } from "../Layout/UserContext"
import axios from 'axios';
axios.defaults.withCredentials = true

let styles = {
    paper: {
        width: "calc(100%-240px)",
        height: "80vh"
    },
    sideButton: {
        height:60
    },
    deleteButton: {
        height:60,
        color: 'red'
    },
    baseProfileInfo: {
        marginLeft:15,
        marginTop:5
    }
}

const getInitials = (displayName) => {
  if(/\s/g.test(displayName)){
    let splitNames = displayName.split(' ')
    var initials = splitNames[0].slice(0, 1).toUpperCase() + splitNames[splitNames.length -1].slice(0, 1).toUpperCase()
  }
  else {
    initials = displayName.slice(0, 2)
  }
  return initials
}

const ProfileNav = (props) => {
    const [modalState, setModalState] = useState(false)
    const {site, setSite} = useContext(UserContext)
    const openModal = () => {setModalState(true)}
    const closeModal = () => {setModalState(false)}

    return(
        <Paper style={styles.paper}>
            <Grid container justify="flex-start" direction="column" alignItems="flex-start">
                <Grid item xs={12}>
                    <Avatar style={props.avStyle}>{getInitials(props.accountObject.displayName)}</Avatar>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6" style={styles.baseProfileInfo}>{props.accountObject.displayName}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" style={styles.baseProfileInfo}>Role: {props.accountObject.position}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" style={styles.baseProfileInfo}>ID: {props.accountObject.id}</Typography>
                </Grid>

                <div style={{width:"100%", marginTop:20}}><Divider/></div>

                <List style={{width:"100%",paddingTop:0}}>
                    <ListItem button style={styles.sideButton} value="profileInfoPage" onClick={() => props.handlePageChange('profileInfoPage')}>
                        <Info/><ListItemText primary="Profile Info" />
                    </ListItem>
                <div style={{width:"100%"}}><Divider/></div>
                    <ListItem button style={styles.sideButton} value="organizationInfoPage" onClick={() =>props.handlePageChange('organizationInfoPage')}>
                        <Business/><ListItemText primary="Organization Info" />
                    </ListItem>
                <div style={{width:"100%"}}><Divider/></div>
                    <ListItem button style={styles.sideButton} value="connectionsInfoPage" onClick={() => props.handlePageChange('connectionsInfoPage')}>
                        <Power/><ListItemText primary="Connections" />
                    </ListItem>
                <div style={{width:"100%"}}><Divider/></div>
                    <ListItem button style={styles.sideButton} value="groupsMembershipsPage" onClick={() => props.handlePageChange('groupsMembershipsPage')}>
                        <Group/><ListItemText primary="Group Memberships" />
                    </ListItem>
                <div style={{width:"100%"}}><Divider/></div>
                {site.roles.delete ? (                
                    <div>
                        <ListItem button style={styles.deleteButton} value="deleteAccount" onClick={openModal} disabled = {false}>
                            <Delete/><ListItemText primary="Delete Account" />
                        </ListItem>
                        <div style={{width:"100%"}}><Divider/></div>
                    </div>):(<div/>)}
                </List>
            </Grid>
            <DeleteAccountModal open={modalState} closeModal={closeModal} accountID = {props.accountObject.id}/>
        </Paper>
    )
}
export default ProfileNav