import React, { useState, useContext } from "react";
import { UserContext } from "../components/Layout/UserContext"
import { FormContext } from "../components/operations_create_single_account/FormContext"
import CreateAccountForm from "../components/operations_create_single_account/AccountForm";
import ResponseModal from "../components/modals/AccountResponseModal"
import axios from 'axios'

let formInputDefaults = {
    firstNameInput: {
        value: "",
        valid: true
    },
    lastNameInput: {
        value: "",
        valid: true
    },
    emailInput: {
        value: "",
        valid: true
    },
    usernameInput: {
        value: "",
        valid: true
    },
    responseModal: {
        open: false
    },
    roleInput:{value: ""},
    accountTypeInput:{value: ""},
    organizationInput:{value: ""},
    schoolInput:{value: ""},
    gradeInput:{value: ""},
    welcomeLetterToggle: false,
    org_admin: false
}

const defaultModalState = {
    open: false,
    bulbResponse: 201,
    accountObject: ''
}

const CreateSingleAccount = (props) => {
    const {site} = useContext(UserContext)
    const [formInputs, setFormInputs] = useState(formInputDefaults)
    const [respModal, setRespModal] = useState(defaultModalState)

    const handleFormSubmit = (event) => {
        let accountObject = {
            email: formInputs.emailInput.value,
            firstName: formInputs.firstNameInput.value,
            lastName: formInputs.lastNameInput.value,
            username: formInputs.usernameInput.value,
            role: formInputs.roleInput.value,
            account_type: formInputs.accountTypeInput.value,
            grade: formInputs.gradeInput.value,
            organization: formInputs.organizationInput.value,
            school: formInputs.schoolInput.value,
            welcome_letter: formInputs.welcomeLetterToggle,
            org_admin: formInputs.org_admin
        }
        axios.post(process.env.REACT_APP_BTS_API_URL + "/api/account?site=" + site.site, accountObject).then(res => {
            let createdAccount = res.data
            setRespModal({
                open: true,
                bulbResponse: createdAccount.status,
                accountObject: createdAccount.data
            })

        })
    }

    const resetForm = () => {
        setFormInputs(formInputDefaults)
        setRespModal(defaultModalState)
    }
    
    return (
        <FormContext.Provider value={{formInputs, setFormInputs}}>
            <div style={{width:"100%"}}>
                <CreateAccountForm submitForm={handleFormSubmit}/>
                <ResponseModal resetForm={resetForm} respModal={respModal} setRespModal={setRespModal}/>
            </div>
        </FormContext.Provider>
    )    
}

export default CreateSingleAccount