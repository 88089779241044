import React from "react";
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

const styles = {
    titelHeaders: {
        marginLeft:60
    },
    fields: {
        marginLeft:85,
        marginTop:"1%"
    },
    fieldValues: {
        margin: 10
    }
}

const FieldPaper = ( {fieldName} ) => {
    return (
        <Grid item xs={4}>
                <Typography variant="h6" style={styles.fieldValues}>{fieldName}</Typography>
        </Grid>
    )
}

export default class OrgInfo extends React.Component {

state={

    orgsArray:this.props.account.groupMemberships.filter(group => group.type === "ORGANIZATION"),
    districtsArray:this.props.account.groupMemberships.filter(group => group.type === "DISTRICT"),
    schoolsArray:this.props.account.groupMemberships.filter(group => group.type === "SCHOOL")
}

  render() {
    const publicPublishingBool = this.props.account.features.PublishingFeature.publicPublishing
    const privateProfileFeature = this.props.account.features.PrivateProfileFeature.enabled
    const profileGradeLevel = this.props.account.grade

    return (
        <div>
        	<Grid container justify="space-between" direction="row" alignItems="flex-start">  

                <Grid item xs={12}>
                    <Typography variant="h5" style={styles.titelHeaders}>Publishing Permissions:</Typography>
                </Grid>   
                <Grid item xs={6}>
                        <Typography variant="h5" style={{marginLeft:"14%",marginTop:20}}>Public Publishing: 
                        
                        {publicPublishingBool?(
                            <Typography variant="h6" style={{color:'green'}}>Enabled</Typography>
                            ):(
                            <Typography variant="h6" style={{color:'red'}}>Disabled</Typography>)
                        }
                        </Typography>
                </Grid> 
                <Grid item xs={6}>
                    <Typography variant="h5" style={{marginTop:20}}>Private Profile: 
                        {privateProfileFeature?(
                            <Typography variant="h6" style={{color:'green'}}>Enabled</Typography>
                            ):(
                            <Typography variant="h6" style={{color:'red'}}>Disabled</Typography>)
                        }
                    </Typography>
                </Grid> 
                <Grid item xs={12}>
                    <div style={{width:"100%", marginBottom:20, marginTop:15}}><Divider/></div>
                </Grid>   


                <Grid item xs={12}>
                    <Typography variant="h5" style={styles.titelHeaders}>Organization(s):</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justify="flex-start" direction="row" alignItems="flex-start" style={{marginLeft:"7%"}}> 
                        {this.state.orgsArray.map(org => {
                            return (<FieldPaper fieldName={org.displayName} />)})
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <div style={{width:"100%", marginBottom:20, marginTop:15}}><Divider/></div>
                </Grid>   



                <Grid item xs={6}>
                    <Typography variant="h5" style={styles.titelHeaders}>School:</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h5">Grade:</Typography>
                </Grid>



                <Grid item xs={6}>
                    <Grid container justify="flex-start" direction="column" alignItems="flex-start" style={{marginLeft:"14%"}}> 
                        {this.state.schoolsArray.map(org => {
                            return (<FieldPaper fieldName={org.displayName} />)})
                        }
                    </Grid>
                </Grid>

                <Grid item xs={6}>
                    <Grid container justify="flex-start" direction="column" alignItems="flex-start" style={{marginLeft:"7%"}}> 
                        <FieldPaper fieldName={profileGradeLevel} />
                    </Grid>
                </Grid>

            </Grid>
       
        </div>

    );
  }
}
