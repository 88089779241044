import React from "react";
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableGroupRow,
  TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import {
  SortingState,
  IntegratedSorting,
  GroupingState,
  IntegratedGrouping,
} from '@devexpress/dx-react-grid';

const styles = {
    paper: {
        width:"90%",
        marginLeft:60
    },
    loadingBar: {
      width:"90%",
      marginLeft:"5%"
    }
}

const getRowId = row => row.id;
const GroupCellContent = ({ column, row }) => (
  <span>
      {column.title}
    <strong>
      { row.value
        ? ': Open'
        : ': Closed'
      }
    </strong>
  </span>
);


export default class VirtualGroupTable extends React.Component {
changeSorting = sorting => this.setState({ sorting });

state = {
    columns: [
        { name: 'displayName', title: 'Group Name' },
        { name: 'description', title: 'Description' },
        { name: 'active', title: 'Status' },
        { name: 'memberCount', title: '# of Members' },
        { name: 'contentPublished', title: '# Published Pages'},
        { name: 'id', title: 'Group ID' }
    ],
    rows:this.props.groupsArray,
    tableColumnExtensions: [
        { columnName: 'displayName', wordWrapEnabled: true },
        { columnName: 'description', wordWrapEnabled: true }
      ],
    sorting:[
        { columnName: 'displayName', direction: 'asc' }
      ],
    sortingStateColumnExtensions:[
        { columnName: 'active', sortingEnabled: false }
    ],
    integratedGroupingColumnExtensions:[
        
    ],
    columnWidths:[
        { columnName: 'displayName', width:220},
        { columnName: 'description', width:220},
        { columnName: 'active', width:100},
        { columnName: 'memberCount', width:150},
        { columnName: 'contentPublished', width:150},
        { columnName: 'id', width:220}
    ]
}

  render() {
    const { rows, columns,tableColumnExtensions,sorting,columnWidths,sortingStateColumnExtensions } = this.state;
    return (
      <div>
        {this.props.loadingGroups ? (
          <LinearProgress style={styles.loadingBar}/>
        ) : (
           <Paper style={styles.paper}>
            <Grid rows={rows} columns={columns} getRowId={getRowId}>
                <SortingState
                    sorting={sorting}
                    columnExtensions={sortingStateColumnExtensions}
                    onSortingChange={this.changeSorting}
                />                 
                <GroupingState
                    grouping={[{ columnName: 'active' }]}
                    defaultExpandedGroups={['true']}
                />
                <IntegratedSorting />
                <IntegratedGrouping />
                <VirtualTable columnExtensions={tableColumnExtensions}/>
                <TableColumnResizing
                    columnWidths={columnWidths}
                />
                <TableHeaderRow 
                    showSortingControls
                />
                <TableGroupRow
                    contentComponent={GroupCellContent}
                />

            </Grid>
        </Paper>       
        )}
      </div>
    );
  }
}
