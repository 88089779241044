import React from "react";
import Typography from '@material-ui/core/Typography'

const NotFound = (props) => {
	const styles = {
		root: {
			width: 'calc(%100-220px)',
			marginTop: 110,
	   		marginLeft: 220,	
		},
		image: {
			width: 200,
	   		height: 200,
	   		display: 'block',
			marginLeft: 'auto',
			marginRight: 'auto',
			marginBottom: 50
		}
	}

	return (
		<div style={styles.root}>
			<img style={styles.image} src={require("../style/holdup.png")}/>
			<Typography align='center' variant='h4'>Page not found or access denied</Typography>
			<Typography align='center' variant='h4'>Please see Nick for help</Typography>
		</div>
	)
}
export default NotFound