import React, { useState, useEffect, useContext } from "react";
import ReactDOM from 'react-dom'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import { FormContext } from "../operations_create_single_account/FormContext"

const AccountTypeDropDownMenu = (props) => {
    const {formInputs, setFormInputs} = useContext(FormContext)

    const [accountTypeLabelWidth, setPositionLabelWidth] = useState(0)
    const [accountTypeLabelRef, setPositionLabelRef] = useState()

    useEffect(() => {
        accountTypeLabelRef ? (setPositionLabelWidth(ReactDOM.findDOMNode(accountTypeLabelRef).offsetWidth)) : (setPositionLabelWidth(0))
    })

    const pickRole = (event) => {
        setFormInputs({
            ...formInputs,
            accountTypeInput: {value: event.target.value}
        })
        if(event.target.value === 'password') {
            setFormInputs({
                ...formInputs,
                accountTypeInput: {value: event.target.value},
                welcomeLetterToggle: true
            })
        }
    }

    return(
        <FormControl variant="outlined" style={props.styles} required="true">
            <InputLabel htmlFor="outlined-AccountType-Label" ref={ref => {setPositionLabelRef(ref)}}>Account Type</InputLabel>
            <Select 
                input={<OutlinedInput id="outlined-AccountType-Label" labelWidth={accountTypeLabelWidth}/>}
                onChange={pickRole} 
                value={formInputs.accountTypeInput.value}
            >
                <MenuItem value="password">Password</MenuItem>
                <MenuItem value="google">Google</MenuItem>
                <MenuItem value="mo365">MO365</MenuItem>
            </Select>
        </FormControl>
    )
}

export default AccountTypeDropDownMenu