import React, { useState, useEffect, useContext } from 'react';
import { Route, Switch, Redirect, useHistory, withRouter, BrowserRouter as Router } from "react-router-dom";
import axios from 'axios';
import './index.css';

import LoginHome from './pages/LoginHome'
import NavigationOverlay from "./components/Layout/NavigationOverlay";
import CreateSingleAccount from './pages/CreateSingleAccount'
import FileValidator from './pages/ValidateFile'
import ApiAccountLayout from './pages/Api_Account'
import ApiGroup from './pages/Api_Group'
import ViewOrg from './pages/ViewOrg'
import Admin from './pages/Admin'
import NotFound from './pages/NotFound'

import { UserContext } from "./components/Layout/UserContext"
axios.defaults.withCredentials = true

const App = () => {
	const [site, setSite] = useState({
		site:'prod',
		toggle:true,
		user: {},
		roles: {
			"admin": false,
			"delete": false,
			"read": false,
			"update": false,
			"write": false}
	})

	const [auth, setAuth] = useState({isAuthenticated:true})
	const [user, setUser] = useState()

    useEffect(() => {
    axios.get(process.env.REACT_APP_BTS_API_URL + "/auth/user/roles")
    .then(res => {
    	setAuth({isAuthenticated:res.data.status})
    	if (res.data.status) {
    		setSite({
    			...site,
    			user:res.data.data.user,
    			roles:res.data.data.user.role_map}
    		)}
		})
	},[])

	const PrivateRoute = ({ component, ...options }) => {
		if(!auth.isAuthenticated) {
			return <Redirect to="/login"/>
		}
		let finalComponent = component
		if(typeof options.roleRequired !== 'undefined'){
			if(!site.roles[options.roleRequired]){
				finalComponent = NotFound
			}
		}
		return <Route {...options} component={finalComponent}/>
	}

	return(
		<Router>
			<Switch>
			<Route path='/login' component={LoginHome}/>
			<UserContext.Provider value={{site, setSite}}>
					<NavigationOverlay user={user}/>
					<PrivateRoute path='/' exact/>
					<PrivateRoute path='/admin' component={Admin} roleRequired='admin'/>
					<PrivateRoute path='/create-account' component={CreateSingleAccount}/>
					<PrivateRoute path='/upload-file' component={FileValidator}/>
					<PrivateRoute path='/api-account' component={ApiAccountLayout}/>
					<PrivateRoute path='/api-group' component={ApiGroup}/>
					<PrivateRoute path='/api-org' component={ViewOrg}/>
			</UserContext.Provider>
			</Switch>
		</Router>
	)
}

export default withRouter(App)