import React, { useState, useEffect, useContext} from "react";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import DeleteOrgModal from "../modals/ConfirmOrganizationDelete"
import RenameOrgModal from "../modals/ConfirmOrganizationRename"

import { UserContext } from "../Layout/UserContext"

import axios from 'axios';
axios.defaults.withCredentials = true

const styles = {
	rename_button: {
		marginLeft: '20%'
	},
	delete_button: {
		marginLeft: '20%',
		color: 'red'
	}
}

const AdminPanel = (props) => {
	const {site} = useContext(UserContext)

    const [modalState, setModalState] = useState({delete:false, rename:false})
    const openModal = (modal) => {setModalState({...modalState,[modal]:true})}
    const closeModal = (modal) => {setModalState({...modalState,[modal]:false})}

	return(
		<div>
		<Paper style = {styles.paper}>
			<Button onClick={()=>openModal('rename')} variant='outlined' style={styles.rename_button}>Rename Org</Button>
			<Button onClick={()=>openModal('delete')} variant='outlined' style={styles.delete_button}>Delete Org</Button>
		</Paper>
		<DeleteOrgModal open={modalState.delete} closeModal={closeModal}/>
		<RenameOrgModal open={modalState.rename} closeModal={closeModal}/>
		</div>
	)
}

export default AdminPanel