import React from 'react'
import Typography from '@material-ui/core/Typography';

let styles = {
	root: {
        width:"calc(100%-240px)", 
        marginTop:100,
        marginLeft: 240
    }
}

const FileValidator = (props) => {
	return(
		<div style={styles.root}>
			<Typography variant="h3">Coming Soon!</Typography>
		</div>
	)
	
}

export default FileValidator