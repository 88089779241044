import React, {useState, useContext} from "react";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import ConnectionCard from './AccountsConnectionsDisplay_Card'
import AddConnection from "../modals/AddConnectionModal"

import { AccountContext } from "./AccountContext"
import { UserContext } from "../Layout/UserContext"

const styles ={
    title: {
        marginLeft: "8.5%",
        display: 'inline'
    },
    addButton: {
        float: 'right',
        marginRight: '16%'
    }
}

const ConnectionCarousel = (props) => {
    const {site, setSite} = useContext(UserContext)
    const {accountObject, setAccountObject} = useContext(AccountContext)
    const [modalState, setModalState] = useState(false)
    const openModal = () => {setModalState(true)}
    const closeModal = () => {setModalState(false)}
        return (    	
    		<div style={{overflow:"auto", margin:"auto"}}>
            <Typography style={styles.title} variant='h4'>Account Connections</Typography>
            {site.roles.write ? ( 
                <Tooltip title="Add Connection">
                    <IconButton style={styles.addButton} aria-label="Add Connection" component="span" onClick={openModal}>
                        <AddCircleOutlineIcon fontSize='large'/>
                    </IconButton>
                </Tooltip>
            ) : (<div/>)}
    			<Grid container alignItems="flex-start" justify="center">

                        {
                        Object.keys(accountObject.account.connections).map(key => {
                        return (<Grid item xs={5}><ConnectionCard type={key} account={accountObject.account}/></Grid>)})
                        }                        
    			</Grid>
            <AddConnection open={modalState} closeModal={closeModal} account={accountObject.account}/>
    		</div>
        )
    }

export default ConnectionCarousel