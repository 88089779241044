import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import Report from '@material-ui/icons/Report';


let statuses = {
	201:{
		title:"Account Creation Successful"
	},
	409:{
		title:"Account Already Exists"
	},
	420:{
		title:"Data Validation Failed"
	},
	500:{
		title:"Account Creation Failed"
	}
}


function ModalContent(props) {

	switch (props.statuscode) {
		case 201:
			return <SuccessfulModalResponse accountObject={props.accountObject}/>

		case 409:
			return <DuplicateModalRespone accountObject={props.accountObject}/>

		case 420:
			return <Typography>Failed backend validation</Typography>

		case 500:
			return <Typography>Sorry, account failed to create. Please contact NickB to report this error.</Typography>
		default:
			return <Typography>Did not receive response from backend</Typography>
	}
}

function SuccessfulModalResponse(props) {

	return(
		<div>

			<Typography>Account Creation: {props.accountObject.account ? (<CheckCircleOutline/>):(<Report/>)}</Typography>
			{props.accountObject.account.hasOwnProperty('groupMemberships') ? (
				<Typography>Organization Association: {props.accountObject.account.groupMemberships ? (<CheckCircleOutline/>):(<Report/>)}</Typography>
				) : (
				<div></div>
				) }
			{props.accountObject.hasOwnProperty('org_admin') ? (
				<Typography>Organization Admin: {props.accountObject.org_admin ? (<CheckCircleOutline/>):(<Report/>)}</Typography>
				) : (
				<div></div>
				) }
			{props.accountObject.hasOwnProperty('welcome_letter') ? (
				<Typography>Welcome Letter: {props.accountObject.welcome_letter ? (<CheckCircleOutline/>):(<Report/>)}</Typography>
				) : (
				<div></div>
				) }
		</div>
	)
}

function DuplicateModalRespone(props) {

	return(
		<div>
			<Typography>Email: {props.accountObject.defaultEmail}</Typography>
			<Typography>Username: {props.accountObject.username}</Typography>
			<Typography>Display Name: {props.accountObject.displayName}</Typography>
			<Typography>Date Created: {props.accountObject.metrics.creationDate}</Typography>
		</div>
	)
}

const ResponseModal = (props) => {
	const respModal = props.respModal
	const setRespModal = props.setRespModal

	const closeModal = () => {
		setRespModal({
			...respModal,
			open: false
		})
	}

    return (
    	<div>
	    	<Dialog onClose={closeModal} open={respModal.open}>
		    	<DialogTitle>{statuses[respModal.bulbResponse].title}</DialogTitle>
		    	<DialogContent style={{width:420}}>
		    		<ModalContent statuscode={respModal.bulbResponse} accountObject={respModal.accountObject}/>
				</DialogContent>
				<DialogActions>
					<Button onClick={closeModal} color="primary">Keep Form</Button>
					<Button onClick={props.resetForm} color="primary" autoFocus>Reset Form</Button>
          		</DialogActions>
		    	</Dialog>
    	</div>

	);
	
}
export default ResponseModal







