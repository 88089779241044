import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';

import { UserContext } from "../Layout/UserContext"
import { FormContext } from "../operations_create_single_account/FormContext"
axios.defaults.withCredentials = true

const CreateOrgModal = (props) => {
    const {site} = useContext(UserContext)
    const {formInputs, setFormInputs} = useContext(FormContext)

    let orgModal = props.value
    let setOrgModal = props.setValue

    const handleTextbox = (event) => {
        setOrgModal({ 
            ...orgModal, 
            value: event.target.value
        })
    }

    const closeModal = () => {
        setOrgModal({ 
            ...orgModal, 
            open: false
        })
    }

    const createOrganization = (value) => {
        let orgObject = {
            "displayName": value,
            "groupType": "DISTRICT"
        }
        axios.post(process.env.REACT_APP_BTS_API_URL + "/api/organization/?site=" + site.site, orgObject)
            .then(res => {
                let createdOrg = res.data
                let orgList = props.orgList
                orgList.push({ "id": createdOrg.id, "name": createdOrg.displayName })
                props.setOrgList(orgList)
                setFormInputs({
                	...formInputs,
                	organizationInput: {value: createdOrg.id}
                })
                setOrgModal({ 
                	value: "",
            		open: false
        		})
            });
    }

    return(
    	<div>
	    	<Dialog open={orgModal.open} onClose={closeModal}>
	    		<DialogTitle>
		    		<Typography>Create an Organization</Typography>
		    	</DialogTitle>
		    	<DialogContent style={{width:420}}>
		    		<TextField
		    			fullWidth 
		    			id="orgNameInput" 
		    			onChange={handleTextbox} 
		    			variant="outlined" label="Organization Name" 
		    			value={orgModal.value}
		    			required="true" 
		    			style={{marginTop:5}}
		    		/>
				</DialogContent>
				<DialogActions>
					<Button onClick={closeModal} color="primary">Cancel</Button>
					<Button onClick={(e) => createOrganization(orgModal.value)} color="primary">Create Org</Button>
		 		</DialogActions>
		    	</Dialog>
    	</div>
    )
}
export default CreateOrgModal