import React, { useContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';

import { UserContext } from "../Layout/UserContext"
import { OrgInfoContext } from "../api_org/OrgInfoContext"

axios.defaults.withCredentials = true

const NoteModal = (props) => {
    const {site} = useContext(UserContext)
    const {workingOrg,setWorkingOrg} = useContext(OrgInfoContext)
    const [modalValue,setModalValue] = useState({value:workingOrg.modals.noteModal.value})

    const handleTextbox = (event) => {
        setModalValue({value:event.target.value})
    }

    const closeModal = () => {
        setWorkingOrg({
            ...workingOrg,
            modals:
                {noteModal:
                    {open:false}
                }
            })
    }

    const onNoteChange = async (noteID) => {
        if (workingOrg.modals.noteModal.type ==="Add") {
            let noteUrl = process.env.REACT_APP_BTS_API_URL + '/api/notes/?site=' + site.site
            let noteOptions = {
                method: 'POST',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    org_id:workingOrg.detailedOrg.id,
                    note: modalValue.value})
            }
            const noteResponse = await fetch(noteUrl, noteOptions)
            const noteResponseData = await noteResponse.json()
            let notesArray = workingOrg.detailedOrg.notes
            if (!("error" in noteResponseData)){
                notesArray.unshift(noteResponseData)
                setWorkingOrg({
                    ...workingOrg,
                    detailedOrg:{
                        ...workingOrg.detailedOrg,
                        notes: notesArray
                    }
                })
            }else{
                console.log("ERROR: Not logged in.")
            }
            closeModal()
        }else if (workingOrg.modals.noteModal.type ==="Edit") {
            let editNoteUrl = process.env.REACT_APP_BTS_API_URL + '/api/notes/'+noteID+'?site=' + site.site
            let editNoteOptions = {
                method: 'PATCH',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    note: modalValue.value})
            }
            const noteResponse = await fetch(editNoteUrl, editNoteOptions)
            const noteResponseData = await noteResponse.json()
            let notesArray = workingOrg.detailedOrg.notes
            const objIndex = notesArray.findIndex(obj => obj.id === noteID);
            notesArray.splice(objIndex,1,noteResponseData)
            setWorkingOrg({
                ...workingOrg,
                detailedOrg:{
                    ...workingOrg.detailedOrg,
                    notes: notesArray
                }
            })
            closeModal()
        }else if (workingOrg.modals.noteModal.type ==="Delete") {
            let deleteNoteUrl = process.env.REACT_APP_BTS_API_URL + '/api/notes/'+noteID+'?site=' + site.site
            let deleteNoteOptions = {
                method: 'DELETE',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'}
            }
            const noteResponse = await fetch(deleteNoteUrl, deleteNoteOptions)
            const noteResponseData = await noteResponse.json()
            let notesArray = workingOrg.detailedOrg.notes
            const objIndex = notesArray.findIndex(obj => obj.id === noteID);
            notesArray.splice(objIndex,1)
            setWorkingOrg({
                ...workingOrg,
                detailedOrg:{
                    ...workingOrg.detailedOrg,
                    notes: notesArray
                }
            })
            closeModal()
        }
    }

    const checkEdit = () => {
        if (workingOrg.modals.noteModal.type ==="Delete") {
            return false
        }else{
            return true
        }
    }

    return(
        <Dialog open={workingOrg.modals.noteModal.open} onClose={closeModal} >
            <DialogTitle>
                <Typography>{workingOrg.modals.noteModal.type} note</Typography>
            </DialogTitle>
                <DialogContent style={{width:500}}>
                {checkEdit() ?(
                    <TextField
                        fullWidth
                        multiline
                        id="noteInput" 
                        variant="outlined"
                        required="true" 
                        rows="4"
                        style={{marginTop:5}}
                        onChange={handleTextbox}
                        value={modalValue.value}
                    />
                    ):(
                    <Typography>Are you sure you want to delete this note?</Typography>
                    )}

                </DialogContent>    
            
            <DialogActions>
                <Button onClick={closeModal} color="primary">Cancel</Button>
                <Button onClick={()=>onNoteChange(workingOrg.modals.noteModal.orgID)} color="primary">{workingOrg.modals.noteModal.type} Note</Button>
            </DialogActions>
        </Dialog>
    )
}
export default NoteModal