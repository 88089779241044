import React, { useState, useEffect, useContext} from "react";
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';

import { UserContext } from "../Layout/UserContext"
import { OrgInfoContext } from "./OrgInfoContext"
import axios from 'axios';
axios.defaults.withCredentials = true

const styles = {
	root: {
		width: '100%',
		display: "flex"
	},
	leftSide: {
		width: "58%"
	},
	infoPanel: {
		marginLeft: 25,
		marginRight: 25,
		marginTop: 20,
		height: "79vh"
	},
	fieldHolder: {
		display: 'flex',
		height: "70vh",
		overflow: 'auto'
	},
	infoField: {

	},
	rightSide: {
		width: "40%",
		marginTop: 20
	},
	notesPanel: {
		height: "79vh"
	},
	notesOverflow: {
		height: "70vh",
		overflow: 'auto'
	},
	notecard: {
		width: "96%",
		marginLeft: "2%",
		marginTop: 15
	},
	noteButton: {
		float: "right",
		marginTop: 3
	},
	editButton: {
		float: "right",
		marginTop: 3
	},
	detailedHeader: {
		marginTop: 25
	},
}

const DataField = (props) => {
		return(
			<Grid item xs={4} style={styles.infoField}>
				<Typography variant='body1' style={{marginTop: -25}}>{props.title}</Typography>
				{props.orgInfo.editMode & props.editable? (
					<TextField value={props.orgInfo.details[props.fieldName]} onChange = {(event)=>props.onFieldEdit(event.target.value,props.fieldName)} style={{marginTop:15}}/>
					):(
					<Typography variant='body1'>{props.orgInfo.details[props.fieldName]}</Typography>
					)}
			</Grid>	
		)	
}

const CalField = (props) => {
	const getFormat = () => {
				if (props.fieldName.includes("default")){
					return "MM/dd"
				}else{
					return "MM/dd/yyyy"
				}
			}
	const formatDate = (date) => {
		if (date){
			if (props.fieldName.includes("default")){
				const dateObj= new Date(date)
				return dateObj.getMonth()+1 + '/'+ dateObj.getDate()
			}else{
				const dateObj= new Date(date)
				return dateObj.toDateString()
			}
		}else{
			return null
		}

	}
	return(
		<Grid item xs={4} style={styles.infoField}>
			<Typography variant='body1' style={{marginTop: -25}}>{props.title}</Typography>
			{props.orgInfo.editMode & props.editable? (
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<KeyboardDatePicker
					margin="normal"
					format={getFormat()}
					view={["month","date"]}
					value={props.orgInfo.details[props.fieldName]}
					onChange= {(event)=>props.onFieldEdit(event,props.fieldName)}
					KeyboardButtonProps={{
					'aria-label': 'change date',
					}}
				/>
				</MuiPickersUtilsProvider>
				):(
				<Typography variant='body1'>{formatDate(props.orgInfo.details[props.fieldName])}</Typography>
				)}
		</Grid>	
	)
}

const menuItems = {
	bool: ["True","False"],
	ordination: ["District","School","Department","Partner","Partner Client","Trial","Internal","ESA","COVID19","Other"],
	touch_level: ["High","Medium","Low"],
	provisioning_method: ["Clever","ClassLink","OSUL","GC"],
	lms: ["Canvas", "Schoology", "Itslearning", "Moodle", "Blackboard"]
}

const VariableDropDown = (props) => {
	return(
		<Grid item xs={4} style={styles.infoField}>
			<Typography variant='body1' style={{marginTop: -25}}>{props.title}</Typography>
			{props.orgInfo.editMode? (
			<Select
				value={props.orgInfo.details[props.fieldName]}
				onChange={(event)=>props.onFieldEdit(event.target.value,props.fieldName)}
				displayEmpty
				style={{width:"80%"}}
		    >
			{menuItems[props.fieldType].map(item => {
				return (
					<MenuItem value={item}>{item}</MenuItem>
				)
			})
			}
			</Select>
			):(
				<Typography variant='body1'>{props.orgInfo.details[props.fieldName]}</Typography>
			)}
		</Grid>	
	)
}

const OrganizationDetails = (props) => {
	const {site} = useContext(UserContext)
	const {workingOrg,setWorkingOrg} = useContext(OrgInfoContext)
	const [orgInfo, setOrgInfo] = useState({editMode:false, details: workingOrg.detailedOrg })
	

	const toggleEditMode = () => {
		setOrgInfo({
			...orgInfo,
			editMode: !orgInfo.editMode
		})
	}

	const onFieldEdit = (event, fieldName) => {
		setOrgInfo({
			...orgInfo,
			details:{
				...orgInfo.details,
				[fieldName]: event}
		})
	}

	const saveFormChanges = () => {
		var patch_request = {}
		for (const property in orgInfo.details) {
			if (orgInfo.details[property] !== workingOrg.detailedOrg[property]) {
				patch_request[property]=orgInfo.details[property]
			}
		}
        axios.patch(process.env.REACT_APP_BTS_API_URL + '/api/organization/'+workingOrg.detailedOrg.bulb_id+'?site=' + site.site,patch_request).then(response => {
        	toggleEditMode()
        })
	}

	return(
		<Paper elevation={1} style={styles.infoPanel}>

			<Grid container style={styles.fieldHolder}>
				<Grid item xs={12}>
					<Typography variant='h4' style={{display:'inline', marginTop:6}}>Details</Typography>
					{orgInfo.editMode & orgInfo.details !== workingOrg.detailedOrg ? (
						<Button startIcon={<SaveIcon color='primary'/>} style={styles.editButton} onClick={saveFormChanges}>Save</Button>
					):(
						<Button startIcon={<EditIcon/>} style={styles.editButton} onClick={toggleEditMode}>Edit</Button>
					)}
					
					<Divider style={{marginTop:1}}/>
				</Grid>
				<DataField orgInfo={orgInfo}
					title='bulb ID' 
					fieldName="bulb_id" 
					fieldType="text" 
					editable={false}
				/>
				<DataField orgInfo={orgInfo}
					title='Total Members' 
					editable={false} 
					fieldName="total_accounts" 
					fieldType="text"
				/>
				<CalField orgInfo={orgInfo}
					title='Creation Date' 
					editable={false} 
					fieldName="creation_date"
				/>

				<VariableDropDown orgInfo={orgInfo} onFieldEdit={onFieldEdit}
					title='Internal' 
					fieldName="internal" 
					fieldType="bool"
				/>
				<VariableDropDown orgInfo={orgInfo} onFieldEdit={onFieldEdit}
					title='Ordination' 
					fieldName="ordination" 
					fieldType="ordination"
				/>
				<VariableDropDown orgInfo={orgInfo} onFieldEdit={onFieldEdit}
					title='Touch Level' 
					fieldName="touch_level" 
					fieldType="touch_level"
				/>

				<CalField orgInfo={orgInfo} onFieldEdit={onFieldEdit}
					title='School Year Start' 
					editable={true} 
					fieldName="school_start"
				/>
				<CalField orgInfo={orgInfo} onFieldEdit={onFieldEdit}
					title='School Year End' 
					editable={true} 
					fieldName="school_end"
				/>
				<CalField orgInfo={orgInfo} onFieldEdit={onFieldEdit}
					title='bulb Launch Date' 
					editable={true} 
					fieldName="bulb_launch_date"
				/>

				<VariableDropDown orgInfo={orgInfo} onFieldEdit={onFieldEdit}
					title='Provisioning Method' 
					fieldName="provisioning_method"
					fieldType="provisioning_method"
				/>
				<VariableDropDown orgInfo={orgInfo} onFieldEdit={onFieldEdit}
					title='Scheduled Sync' 
					fieldName="scheduled_sync" 
					fieldType="bool"
				/>
				<VariableDropDown orgInfo={orgInfo} onFieldEdit={onFieldEdit}
					title='LMS' 
					fieldName="lms" 
					fieldType="lms"
				/>

				<CalField orgInfo={orgInfo}
					title='Last Updated' 
					editable={false} 
					fieldName="last_update_date"
				/>
				<CalField orgInfo={orgInfo} onFieldEdit={onFieldEdit}
					title='Default Start Date' 
					editable={true} 
					fieldName="default_start_date"
				/>
				<CalField orgInfo={orgInfo} onFieldEdit={onFieldEdit}
					title='Default End Date' 
					editable={true} 
					fieldName="default_end_date"
				/>

				<VariableDropDown orgInfo={orgInfo} onFieldEdit={onFieldEdit}
					title='Send Welcome Letters' 
					fieldName="send_letters" 
					fieldType="bool"
				/>
				<VariableDropDown orgInfo={orgInfo} onFieldEdit={onFieldEdit}
					title='Include in Org Rollup' 
					fieldName="reporting_inclusion" 
					fieldType="bool"
				/>
			</Grid>
		</Paper>
	)
}
export default OrganizationDetails