import React, { useContext } from 'react';
import { useHistory } from "react-router-dom";

import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import Avatar from '@material-ui/core/Avatar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Settings from '@material-ui/icons/Settings';
import PersonAdd from '@material-ui/icons/PersonAdd'
import Person from '@material-ui/icons/Person'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import GroupAdd from '@material-ui/icons/GroupAdd'
import Group from '@material-ui/icons/Group'
import Business from '@material-ui/icons/Business'
import FileCopy from '@material-ui/icons/FileCopy'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'

import { UserContext } from "./UserContext"

const styles = {
	root: {
		display: 'flex'
	},
	bulbLogo: {
		top: 10,
		left: 50, 
		position: 'absolute'
	},
	rightMenus: {
		textAlign: 'right',
		width: '100%',
		marginRight: 20
	},
	leftNavDrawer: {
		width: 240,
		maxWidth: 240,
		zIndex:1
	},
	ddMenus: {
		width: 200
	}
}

function CreateAccountButton() {
  let history = useHistory();
  function handleClick() {
    history.push("/create-account");
  }
  return (
  	<ListItem button onClick={handleClick}>
		<ListItemIcon>
			<PersonAdd/>
		</ListItemIcon>
		<ListItemText primary="Create Account" />
	</ListItem>
  );
}
function UploadFileButton() {
  let history = useHistory();
  function handleClick() {
    history.push("/upload-file");
  }
  return (
	<ListItem button onClick={handleClick}>
		<ListItemIcon>
			<FileCopy/>
		</ListItemIcon>
		<ListItemText primary="Process Org File" />
	</ListItem>
  );
}
function APIAccountButton() {
  let history = useHistory();
  function handleClick() {
    history.push("/api-account");
  }
  return (
	<ListItem button onClick={handleClick}>
		<ListItemIcon>
			<Person/>
		</ListItemIcon>
		<ListItemText primary="View Account" />
	</ListItem>
  );
}
function APIGroupButton() {
  let history = useHistory();
  function handleClick() {
    history.push("/api-group");
  }
  return (
	<ListItem button onClick={handleClick}>
		<ListItemIcon>
			<Group/>
		</ListItemIcon>
		<ListItemText primary="View Group" />
	</ListItem>
  );
}
function APIOrgButton() {
  let history = useHistory();
  function handleClick() {
    history.push("/api-org");
  }
  return (
	<ListItem button onClick={handleClick}>
		<ListItemIcon>
			<Business/>
		</ListItemIcon>
		<ListItemText primary="View Org" />
	</ListItem>
  );
}
function StagingToggleButton() {
  const {site, setSite} = useContext(UserContext)
  const handleClick = (event) =>  {
  	setSite({
  		...site,
  		site: event.target.value})
  }
  return (
		<FormControl style={{marginLeft:10, marginTop:5}}>
		  <FormLabel id="demo-radio-buttons-group-label">Site</FormLabel>
		  <RadioGroup
		    aria-labelledby="demo-radio-buttons-group-label"
		    name="radio-buttons-group"
		    defaultValue="prod"
		    value={site.site}
		    onChange={handleClick}
		  >
		    <FormControlLabel value="prod" control={<Radio />} label="Prod" />
		    <FormControlLabel value="staging" control={<Radio />} label="Staging" />
		    <FormControlLabel value="eu" control={<Radio />} label="EU" />
		  </RadioGroup>
		</FormControl>
  );
}

function AdminNavigation() {
	const {site, setSite} = useContext(UserContext)
	function handleClick(){
		window.location.replace('/admin')
	}
	return (
		<div>
		{site.roles.admin ? (
			<MenuItem onClick={handleClick}>
				<Typography>Admin</Typography>
			</MenuItem>
		):(<div/>)}
		</div>
	);
}

function LogoutButton() {
  function handleClick() {
  	axios.post(process.env.REACT_APP_BTS_API_URL + "/auth/logout",{withCredentials: true})
	.then(res => {
			window.location.replace('/login')
	})
  }
  return (
	<MenuItem onClick={handleClick}>
		<Typography>Logout</Typography>
	</MenuItem>
  );
}

function SettingsCog() {
	const {site} = useContext(UserContext)
	console.log(site)
	if (site.site ==='staging') {
		return(
			<Settings style={{color:'yellow'}}/>
		)
	} else if (site.site === 'eu') {
		return(
			<Settings style={{color:'red'}}/>
		)
	}
	else{
		return(
			<Settings/>
		)
	}

}

export default class NavigationOverlay extends React.Component {

	state = {
	    topNavSettings: {
	        settingsMenu: {
	            menuAnchor: null
	        },
	        accountMenu: {
	            menuAnchor: null
	        }
	    },
	    title: "Home"
	}
	handleMenuOpen = (e, name) => {
	    let topNavSettings = this.state.topNavSettings
	    topNavSettings[name].menuAnchor = e.currentTarget
	    this.setState({ topNavSettings })
	}

	handleMenuClose = (e, name) => {
	    let topNavSettings = this.state.topNavSettings
	    topNavSettings[name].menuAnchor = null
	    this.setState({ topNavSettings })
	}

	handleNavigation = (e, page) => {
		let history = useHistory();
		history.push(page)
	}

	render() {
		const { settingsMenu, accountMenu } = this.state.topNavSettings
		return (
			<div style={styles.root}>
		        <AppBar position="fixed" style={{zIndex:100}}>
	        		<Toolbar>
	          				<IconButton><Menu/></IconButton>
	          				<img src={require("./bulb_BTS.png")} alt='logo' style={styles.bulbLogo}/>
							<div style={styles.rightMenus}>
			          			<IconButton onClick={e => this.handleMenuOpen(e, "settingsMenu")}><SettingsCog/></IconButton>
				          		<Menu id="settings-menu" 
				          			anchorEl={settingsMenu.menuAnchor} 
				          			open={Boolean(settingsMenu.menuAnchor)} 
				          			onClose={e => this.handleMenuClose(e, "settingsMenu")} 
				          		>
				          			<div style={styles.ddMenus}>
				          				<StagingToggleButton/>
				          				<Divider/>
													<AdminNavigation/>	          				
				          			</div>
				          		</Menu>
		          				<IconButton style={{align:'right'}} onClick={e => this.handleMenuOpen(e, "accountMenu")}><AccountCircle/></IconButton>
		          				<Menu id="account-menu" anchorEl={accountMenu.menuAnchor} open={Boolean(accountMenu.menuAnchor)} onClose={e => this.handleMenuClose(e, "accountMenu")}>
			          				<div style={styles.ddMenus}>
					          			<LogoutButton/>
					          		</div>
				          		</Menu>
							</div>
	        		</Toolbar>
      			</AppBar>

      			<Drawer variant="permanent" style={styles.leftNavDrawer}>
      			<div style={{marginTop:65}}>
					<List component="nav">
						<CreateAccountButton/>
						<UploadFileButton/>
					</List>
	      			<Divider/>
	      			<List component="nav">
						<APIAccountButton/>
						<APIGroupButton/>
						<APIOrgButton/>
					</List>
				</div>
      			</Drawer>


			</div>

  );
 }
}
