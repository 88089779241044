import React from "react";
import ReactDOM from 'react-dom';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';

import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';

const styles = {
    formControl: {
        minWidth: "80%"
    },
    textField: {
        minWidth: "80%"
    },
    icons: {
        marginTop: 22
    }
}

export default class ProfileInfo extends React.Component {

state = {
}


componentDidMount() {
    if (this.props.editMode) {
        this.setState({
          labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
        });
    }
  }


handleChange = name => event => {
this.setState({
  [name]: event.target.value,
})
}

checkVerifiedEmail() {
    let defaultEmailVerification = this.props.accountObject.emails.filter(email => email.email === this.props.accountObject.defaultEmail)
    if (defaultEmailVerification.length !== 0){
        if (defaultEmailVerification[0].verified){
            return (
                <Tooltip title="Verified">
                    <CheckCircleOutline style={styles.icons}/>
                </Tooltip>
                )
        }
    }
}

  render() {
    return (
        <div style={{width:"100%"}}>
        	<Grid container justify="space-between" direction="row" alignItems="flex-start">
                <Grid item xs={4}>
                    <TextField
                        id="firstNameInput"
                        //onChange={(e) => this.props.handleChange(e, 'firstName')}
                        label="First Name"
                        value={this.props.accountObjectToUpdate.firstName}
                        style={styles.textField}
                        //variant={this.props.editMode ? "outlined":"standard"}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        id="lastNameInput"
                        //onChange={(e) => this.props.handleChange(e, 'lastName')}
                        label="Last Name" value={this.props.accountObjectToUpdate.lastName}
                        style={styles.textField}
                        //variant={this.props.editMode ? "outlined":"standard"}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        id="displayNameInput"
                        //onChange={(e) => this.props.handleChange(e, 'displayName')}
                        label="Display Name"
                        value={this.props.accountObjectToUpdate.displayName}
                        style={styles.textField}
                        //variant={this.props.editMode ? "outlined":"standard"}
                    />
                </Grid>
                <Grid item xs={12}>
                    <div style={{height:25}}/>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        id="defaultEmailInput"
                        //onChange={(e) => this.props.handleChange(e, 'defaultEmailInput')}
                        label="Default Email"
                        value={this.props.accountObjectToUpdate.defaultEmail}
                        style={styles.textField}

                    />
                    {this.checkVerifiedEmail()}
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        id="usernameInput"
                        //onChange={(e) => this.props.handleChange(e, 'username')}
                        label="Username"
                        value={this.props.accountObject.username}
                        style={styles.textField}

                    />
                </Grid>
                <Grid item xs={4}>
                    {this.props.editMode ?
                        (
                            <FormControl variant = 'outlined' style={styles.formControl}>
                                <InputLabel id="demo-simple-select-outlined-label">Position</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    onChange={(e) => this.props.handleChange(e, 'position')}
                                    value={this.props.accountObjectToUpdate.position}
                                    label='Position'
                                    >
                                    <MenuItem value="STUDENT">Student</MenuItem>
                                    <MenuItem value="EDUCATOR">Educator</MenuItem>
                                    <MenuItem value="ADMINISTRATOR">Administrator</MenuItem>
                                    <MenuItem value="OTHER">Other</MenuItem>
                                    <MenuItem value="UNSPECIFIED">Unspecified</MenuItem>
                                </Select>
                            </FormControl>
                        ):(
                            <TextField id="rolePlaceholder" label="Position" value={this.props.accountObject.position} style={styles.textField}/>
                        )
                    }

                </Grid>

                <Grid item xs={12}>
                    <div style={{height:25}}/>
                </Grid>

                <Grid item xs={4}>
                    {this.props.accountObject.position === "STUDENT" ?
                    this.props.editMode ?
                        (
                        <FormControl variant = 'outlined' style={styles.formControl}>
                                <InputLabel id="demo-simple-select-outlined-label">Grade</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    onChange={(e) => this.props.handleChange(e, 'grade')}
                                    value={this.props.accountObjectToUpdate.grade}
                                    label='Grade'
                                >
                                    <MenuItem value="PRESCHOOL">PRESCHOOL</MenuItem>
                                    <MenuItem value="KINDERGARTEN">KINDERGARTEN</MenuItem>
                                    <MenuItem value="FIRST">KINDERGARTEN</MenuItem>
                                    <MenuItem value="SECOND">SECOND</MenuItem>
                                    <MenuItem value="THIRD">THIRD</MenuItem>
                                    <MenuItem value="FOURTH">FOURTH</MenuItem>
                                    <MenuItem value="FIFTH">FIFTH</MenuItem>
                                    <MenuItem value="SIXTH">SIXTH</MenuItem>
                                    <MenuItem value="SEVENTH">SEVENTH</MenuItem>
                                    <MenuItem value="EIGHTH">EIGHTH</MenuItem>
                                    <MenuItem value="NINTH">NINTH</MenuItem>
                                    <MenuItem value="TENTH">TENTH</MenuItem>
                                    <MenuItem value="ELEVENTH">ELEVENTH</MenuItem>
                                    <MenuItem value="TWELFTH">TWELFTH</MenuItem>
                                    <MenuItem value="POSTGRADUATE">POSTGRADUATE</MenuItem>
                                    <MenuItem value="OTHER">OTHER</MenuItem>
                                    <MenuItem value="UNASSIGNED">UNASSIGNED</MenuItem>
                                </Select>
                            </FormControl>
                        ):(
                            <TextField id="rolePlaceholder" label="Grade" value={this.props.accountObject.grade} style={styles.textField}/>
                        ) : <div></div>}

                </Grid>
            </Grid>

        </div>

    );
  }
}
