import React, { useContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import axios from 'axios';

import { UserContext } from "../Layout/UserContext"
import { AccountContext } from "../api_account/AccountContext"

axios.defaults.withCredentials = true

const DeleteConnectionModal = (props) => {
    const {site} = useContext(UserContext)
    const {accountObject, setAccountObject} = useContext(AccountContext)
    const [snack,setSnack] = useState(false)

    const deleteConnection = () => {
        axios.delete(process.env.REACT_APP_BTS_API_URL + "/api/account/"+props.account.accountID+"/connections/"+props.account.connectionID+'?site='+site.site)
            .then(res => {
                let cleanArray = accountObject.account.connections[props.account.connection_type].filter(function(e) { return e.id != props.account.connectionID})
                setAccountObject({
                    ...accountObject,
                    account:{
                        ...accountObject.account,
                        connections:{
                            ...accountObject.account.connections,
                            [props.account.connection_type]: cleanArray
                        }
                    } 
                })
                props.closeModal()
                setSnack(true)                
            })
    }
    
    const handleClose = () => {
        setSnack(false)
    }

    return(
        <div>
        <Dialog open={props.open} onClose={props.closeModal} >
            <DialogTitle>
                <Typography>Deleting Connection</Typography>
            </DialogTitle>
                <DialogContent style={{width:500}}>
                    <Typography>Are you sure you want to delete this connection?</Typography>
                </DialogContent>    
            
            <DialogActions>
                <Button onClick={props.closeModal} color="primary">Cancel</Button>
                <Button onClick={deleteConnection} color="primary">Permanently Delete</Button>
            </DialogActions>

        </Dialog>
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={snack}
            onClose={handleClose}
            autoHideDuration={4000}
            message="Connection Removed"
        />      
        </div>
    )
}
export default DeleteConnectionModal