import React, { useContext, useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import axios from 'axios';

import { UserContext } from "../Layout/UserContext"
import { OrgInfoContext } from "../api_org/OrgInfoContext"

axios.defaults.withCredentials = true

const RenameOrgModal = (props) => {
    const {site} = useContext(UserContext)
    const [snack,setSnack] = useState(false)
    const {workingOrg,setWorkingOrg} = useContext(OrgInfoContext)
    const [textData, setTextData] = useState(workingOrg.detailedOrg.name)

    const renameOrg= () => {
        let patch_request = {'name':textData}
        axios.patch(process.env.REACT_APP_BTS_API_URL + '/api/organization/'+workingOrg.detailedOrg.bulb_id+'?site=' + site.site,patch_request).then(response => {
            setWorkingOrg({
                ...workingOrg,
                detailedOrg: {
                    ...workingOrg.detailedOrg,
                    'name': textData
                }
            })
            props.closeModal('rename')
            setSnack(true)
        })
    }
    
    const handleClose = () => {
        setSnack(false)
    }

    const handleChange = (event) => {
        setTextData(event.target.value)
    }

    return(
        <div>
        <Dialog open={props.open} onClose={()=>props.closeModal('rename')} >
            <DialogTitle>
                <Typography>Renaming Organization</Typography>
            </DialogTitle>
                <DialogContent style={{width:500}}>
                    <TextField value={textData} onChange={(event =>handleChange(event))} fullWidth/>
                </DialogContent>    
            
            <DialogActions>
                <Button onClick={()=>props.closeModal('rename')} color="primary">Cancel</Button>
                <Button onClick={renameOrg} color="primary">Rename</Button>
            </DialogActions>

        </Dialog>
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={snack}
            onClose={handleClose}
            autoHideDuration={4000}
            message="Organization Renamed"
        />      
        </div>
    )
}
export default RenameOrgModal