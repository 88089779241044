import React, { useState, useEffect, useContext } from "react";
import ReactDOM from 'react-dom'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import { FormContext } from "../operations_create_single_account/FormContext"

const AccountTypeDropDownMenu = (props) => {
    const {formInputs, setFormInputs} = useContext(FormContext)

    const [positionLabelWidth, setPositionLabelWidth] = useState(0)
    const [positionLabelRef, setPositionLabelRef] = useState()

    useEffect(() => {
        positionLabelRef ? (setPositionLabelWidth(ReactDOM.findDOMNode(positionLabelRef).offsetWidth)) : (setPositionLabelWidth(0))
    })

    const pickRole = (event) => {
        setFormInputs({
            ...formInputs,
            roleInput: {value: event.target.value}
        })
    }

    return(
        <FormControl variant="outlined" style={props.styles} required="true">
            <InputLabel htmlFor="outlined-AccountType-Label" ref={ref => {setPositionLabelRef(ref)}}>Role</InputLabel>
            <Select 
                input={<OutlinedInput id="outlined-AccountType-Label" labelWidth={positionLabelWidth}/>}
                onChange={pickRole} 
                value={formInputs.roleInput.value}
            >
                    <MenuItem value="STUDENT">Student</MenuItem>
                    <MenuItem value="EDUCATOR">Educator</MenuItem>
                    <MenuItem value="ADMINISTRATOR">Administrator</MenuItem>
                    <MenuItem value="OTHER">Other</MenuItem>
            </Select>
        </FormControl>
    )
}

export default AccountTypeDropDownMenu