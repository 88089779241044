import React, { useState, useEffect } from 'react'

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import Checkbox from '@material-ui/core/Checkbox';

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import axios from 'axios'
axios.defaults.withCredentials = true

const styles = {
	root: {
	    width:"calc(100%-240px)",
	    marginTop: 70,
	    marginLeft: 240
	},
	tablePaper: {
		marginTop: 10, 
		width:"98%"
	}
}

const Admin = (props) => {
	const [btsUsers, setBtsUsers] = useState()

    useEffect(() => {
    axios.get(process.env.REACT_APP_BTS_API_URL + "/auth/users",{withCredentials: true})
    .then(res => {
    	setBtsUsers(res.data.data)
    })},[])

	const handleChange = (e, user, role, role_id) => {
		let updatedUserRole = !btsUsers[user].role_map[role]
		let requestObject = {'role_type':role_id}
		if (updatedUserRole) {
			axios.post(process.env.REACT_APP_BTS_API_URL + "/auth/user/"+user+"/role/"+role_id)
    		.then(res => {
		    	setBtsUsers({
					...btsUsers,
					[user]: {...btsUsers[user],'role_map':{...btsUsers[user].role_map, [role]:updatedUserRole}}
				})	
    		})		
		} else {
			axios.delete(process.env.REACT_APP_BTS_API_URL + "/auth/user/"+user+"/role/"+role_id)
    		.then(res => {
		    	setBtsUsers({
					...btsUsers,
					[user]: {...btsUsers[user],'role_map':{...btsUsers[user].role_map, [role]:updatedUserRole}}
				})	
    		})		
		}
  	}
	return(
		<div style={styles.root}>
			<Typography variant='h5' align='center' style={{marginTop:20}}>BTS Admin</Typography>
			{btsUsers ? (
			<Paper style={styles.tablePaper}>
			<TableContainer style={{height:"80vh"}}>
				<Table stickyHeader>
				<TableHead>
					<TableRow>
						<TableCell style={{width:40}}></TableCell>
						<TableCell>Name</TableCell>
						<TableCell>Read</TableCell>
						<TableCell>Write</TableCell>
						<TableCell>Update</TableCell>
						<TableCell>Delete</TableCell>
						<TableCell>Admin</TableCell>
						<TableCell>Org Delete</TableCell>
					</TableRow>
				</TableHead>
				<TableBody >
					{Object.keys(btsUsers).map(user=>{return(
						<TableRow>
							<TableCell><Avatar alt={btsUsers[user].name} src={btsUsers[user].google_image}/></TableCell>
							<TableCell>{btsUsers[user].name}</TableCell>
							<TableCell>
								<Checkbox
        							checked={btsUsers[user].role_map.read}
        							onChange={(e)=>{handleChange(e,user,'read',1)}}
        						/>
        					</TableCell>
							<TableCell>
								<Checkbox
        							checked={btsUsers[user].role_map.write}
        							onChange={(e)=>{handleChange(e,user,'write',2)}}
        						/>
        					</TableCell>
							<TableCell>
								<Checkbox
        							checked={btsUsers[user].role_map.update}
        							onChange={(e)=>{handleChange(e,user,'update',3)}}
        						/>
        					</TableCell>
							<TableCell>
								<Checkbox
        							checked={btsUsers[user].role_map.delete}
        							onChange={(e)=>{handleChange(e,user,'delete',4)}}
        						/>
        					</TableCell>
							<TableCell>
								<Checkbox
        							checked={btsUsers[user].role_map.admin}
        							onChange={(e)=>{handleChange(e,user,'admin',5)}}
        						/>
        					</TableCell>
        					<TableCell>
								<Checkbox
        							checked={btsUsers[user].role_map.org_delete}
        							onChange={(e)=>{handleChange(e,user,'org_delete',6)}}
        						/>
        					</TableCell>
						</TableRow>				
					)})}

				</TableBody>
				</Table>
			</TableContainer>
			</Paper>
			) : ('loading')
			}
		</div>
	)
}

export default Admin