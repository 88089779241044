import React from "react";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import axios from 'axios';

axios.defaults.withCredentials = true

const styles = {
	root: {
		width: "100vw",
		height:"100vh"
	}
}

const LoginHome = (props) => {

const login = () => {
	window.location.replace('/auth/login')
}

  return(
  	<div style={styles.root}>
  		<Typography align={'center'} variant="h2">bulb BTS</Typography>
  		<div style={{width:"100%",textAlign:"center"}}>
  		<Button variant="outlined" color="primary" onClick={()=>login()}>Login With Google</Button>
  		</div>
  		<Typography style={{marginLeft:"45%"}}>{props.isLoading}</Typography>
  	</div>
  )
}

export default LoginHome