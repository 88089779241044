import React, { useState, useEffect, useContext} from "react";
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

import AddIcon from '@material-ui/icons/Add';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import NoteModal from '../modals/NoteModal'
import { UserContext } from "../Layout/UserContext"
import { OrgInfoContext } from "./OrgInfoContext"

const styles = {
	notesPanelPart: {
		height: "70vh",
		marginTop: 15
	},
	notesPanelFull: {
		height: "79vh"
	},
	notesOverflowPart: {
		height: "65vh",
		overflow: 'auto'
	},
	notesOverflowFull: {
		height: "70vh",
		overflow: 'auto'
	},
	notecard: {
		width: "96%",
		marginLeft: "2%",
		marginTop: 15
	},
	noteButton: {
		float: "right",
		marginTop: 3
	}
}

const NoteCard = (props) => {
	const {workingOrg,setWorkingOrg} = useContext(OrgInfoContext)
	let initials = props.note.created_by.name.split(" ")[0].charAt(0).toUpperCase() + props.note.created_by.name.split(" ")[1].charAt(0).toUpperCase()
	const formatDate = (create_date,update_date) => {
		var cdt = new Date(create_date)
		var udt = new Date(update_date)
		const clean_date = (dt) => {return dt.getMonth()+1 +'-'+ dt.getDate() +'-'+ dt.getFullYear()}
		if(create_date === update_date){
			return 'Created: '+ clean_date(cdt)
		}else{
			return 'Created: '+ clean_date(cdt) + ' Updated: ' + clean_date(udt)
		}
	}
	const setAnchor = (event) => {
		if (!props.noteAnchors[props.note.id]){
			props.setNoteAnchors({
				...props.noteAnchors,
				[props.note.id]: event.currentTarget
			})			
		}else{
			props.setNoteAnchors({
				...props.noteAnchors,
				[props.note.id]: null
			})				
		}

	}
	const onAction = (action) => {
		setWorkingOrg({
			...workingOrg,
				modals:
				{noteModal:{
					open:true,
					type: action,
					orgID: props.note.id,
					value: props.note.note}}
		})
	}
	return(
		<div>
		<Card style={styles.notecard}>
			<CardHeader
				avatar={<Avatar alt={initials} src={props.note.created_by.google_image}/>}
				action={<IconButton onClick={event =>setAnchor(event)}><MoreVertIcon/></IconButton>}
				title={props.note.created_by.name}
				subheader={formatDate(props.note.creation_date,props.note.update_date)}
			/>

			<CardContent>
				<Typography variant='body1'>{props.note.note}</Typography>
			</CardContent>
		</Card>
		{props.noteAnchors[props.note.id]? (
			<Menu anchorEl={props.noteAnchors[props.note.id]} open={Boolean(props.noteAnchors[props.note.id])} onClose={event =>setAnchor(event)}>
				<MenuItem onClick={()=>onAction('Edit')}>Edit</MenuItem>
				<MenuItem onClick={()=>onAction('Delete')}>Delete</MenuItem>
			</Menu>	
		):(<div></div>)}
		</div>
	)
}

const NotesPanel = (props) => {
	const {site} = useContext(UserContext)
	const {workingOrg,setWorkingOrg} = useContext(OrgInfoContext)
	const [noteAnchors, setNoteAnchors] = useState({})

	const openNoteModal = (type,orgID) => {
	    setWorkingOrg({
	        ...workingOrg,
	        modals:
	            {noteModal:{
	            	...workingOrg.modals.noteModal,
	            	type: type,
	            	orgID:orgID,
	                open:true}
	            }
	        })
	}
	const noteSort = (a,b) => {
		const formatDate = (create_date,update_date) => {
			var cdt = new Date(create_date)
			var udt = new Date(update_date)
			if(create_date === update_date){
				return cdt
			}else{
				return udt
			}
		}
		return formatDate(b.creation_date,b.update_date) - formatDate(a.creation_date,a.update_date)
	}
	return(
		<div>
		<Paper elevation={1} style={site.roles.org_delete ? (styles.notesPanelPart) : (styles.notesPanelFull)}>
			<Typography variant='h4' style={{display:'inline'}}>Notes</Typography>
			<Button startIcon={<AddIcon/>} style={styles.noteButton} onClick={()=>openNoteModal('Add','')}>Add Note</Button>
			<Divider/>
			<div style= {site.roles.org_delete ? (styles.notesOverflowPart) : (styles.notesOverflowFull)}>
			{workingOrg.detailedOrg.notes.length >=1 ? (
				workingOrg.detailedOrg.notes.sort(noteSort).map(note => {
					return (
						<NoteCard note={note} noteAnchors= {noteAnchors} setNoteAnchors= {setNoteAnchors}/>
						)
					}
				)) : (
				<Typography variant='body1' style={{fontSize:20, marginTop: 5}}>No notes found</Typography> 
				)
			}
			</div>
		</Paper>
		<NoteModal/>
		</div>
	)
}

export default NotesPanel