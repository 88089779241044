import React, { useState, useEffect, useContext } from "react";
import ReactDOM from 'react-dom';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import axios from 'axios';

import { UserContext } from "../Layout/UserContext"
import CreateGradeModal from "../modals/CreateGradeModal"

const gradeModalDefault = {
    open: false,
    value: ""
} 

const gradeNameMapping = {
    PRESCHOOL: "Preschool",
    KINDERGARTEN: "Kindergarten",
    FIRST: "1st Grade",
    SECOND: "2nd Grade",
    THIRD: "3rd Grade",
    FOURTH: "4th Grade",
    FIFTH: "5th Grade",
    SIXTH: "6th Grade",
    SEVENTH: "7th Grade",
    EIGHTH: "8th Grade",
    NINTH: "9th Grade",
    TENTH: "10th Grade",
    ELEVENTH: "11th Grade",
    TWELFTH: "12th Grade",
    POSTGRADUATE: "Postgraduate",
    OTHER: "Other"
}

const sortByName = (a, b) => {
    if (a.displayName === "PRESCHOOL") {return -1}
    else if (a.displayName === "KINDERGARTEN") {return -1}
    else if (a.displayName === "OTHER") {return 1}    
    else{
        return gradeNameMapping[a.displayName].substring(0, gradeNameMapping[a.displayName].length - 8) - gradeNameMapping[b.displayName].substring(0, gradeNameMapping[b.displayName].length - 8)
    }
}



const GradeDropDownMenu = (props) => {
    const {site} = useContext(UserContext)

    const [gradeLabelWidth, setGradeLabelWidth] = useState(0)
    const [gradeLabelRef, setGradeLabelRef] = useState()

    let formInputs = props.values
    let setFormInputs = props.setValues

    useEffect(() => {
        gradeLabelRef ? (setGradeLabelWidth(ReactDOM.findDOMNode(gradeLabelRef).offsetWidth)) : (setGradeLabelWidth(0))
    })

    const pickGrade = (event) => {
        setFormInputs({
            ...formInputs,
            'gradeInput': {value: event.target.value}
        })
    }
    return(
        <FormControl variant="outlined" style={props.styles.leftFields}>
            <InputLabel htmlFor="outlined-Grade-Label" ref={ref => {setGradeLabelRef(ref)}}>Grade</InputLabel>
            <Select 
                input={<OutlinedInput id="outlined-Grade-Label" labelWidth={gradeLabelWidth}/>} 
                onChange={pickGrade} 
                value={formInputs.gradeInput.value}
            >

            {Object.keys(gradeNameMapping).map(grade =>
                <MenuItem value={grade} style={{marginLeft: 0}}>{gradeNameMapping[grade]}</MenuItem> 
            )}

            </Select>

        </FormControl>
    )
}

export default GradeDropDownMenu